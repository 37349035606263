import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import {
  makeStyles,
  createStyles,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  SvgIconProps,
  SvgIcon,
} from '@material-ui/core';
import { ITheme } from '../../common/theme';
import { useStore } from '../../common/stores/store';
import LoginRequired from '../../components/LoginRequired';
import InfoBox from '../../components/InfoBox';
import {
  certificatePath, certNewPath, recordsPath,
} from '../../common/constants/routes';
import ErrorInfoBox from '../../components/ErrorInfoBox';
import { DEFAULT_RECORDS_PAGE_SIZE } from '../../common/models/record';
import { useTranslation } from 'react-i18next';
import QRCode from "qrcode.react";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: { ...theme.mixins.verticalAlign, height: '100%' },
    table: {
      minWidth: 480,
    },
    wrapper: {
      ...theme.mixins.wrapper,
      minHeight: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      maxWidth: '90vw',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
    topSection: {
      ...theme.mixins.verticalAlign,
      width: '100%',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(4),
    },
    searchBarRoot: {
      marginBottom: theme.spacing(1),
      flexGrow: 1,
      '& .MuiOutlinedInput-root': {
        '&:hover fieldSet': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        border: '2px solid',
        borderRadius: '6px',
      },
    },
    addRecordButton: {
      ...theme.mixins.defaultButton,
      width: 'auto',
      fontWeight: theme.typography.fontWeightMedium,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    nameCell: {
      ...theme.mixins.overflowEllipsis,
      maxWidth: '180px',
      [theme.breakpoints.down('xs')]: {
        width: '100px',
        maxWidth: '100px',
      },
    },
    walletButtonLabel: {
      ...theme.mixins.overflowEllipsis,
      display: 'block',
    },
  })
);

const ScanQRIcon = (props: SvgIconProps) => (
  <SvgIcon width={30} height={30} {...props}>
    <path d="M9.5 6.5v3h-3v-3h3M11 5H5v6h6V5zm-1.5 9.5v3h-3v-3h3M11 13H5v6h6v-6zm6.5-6.5v3h-3v-3h3M19 5h-6v6h6V5zm-6 8h1.5v1.5H13V13zm1.5 1.5H16V16h-1.5v-1.5zM16 13h1.5v1.5H16V13zm-3 3h1.5v1.5H13V16zm1.5 1.5H16V19h-1.5v-1.5zM16 16h1.5v1.5H16V16zm1.5-1.5H19V16h-1.5v-1.5zm0 3H19V19h-1.5v-1.5zM22 7h-2V4h-3V2h5v5zm0 15v-5h-2v3h-3v2h5zM2 22h5v-2H4v-3H2v5zM2 2v5h2V4h3V2H2z"></path>
  </SvgIcon>
);

const shortenTableString = (s: string) =>
  s ? s.slice(0, 5) + '...' + s.slice(-3) : '-';

const CertificateList = () => {
  const { t } = useTranslation(['records', 'bookmarks']);
  const classes = useStyles();
  const theme = useTheme();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    userStore,
    dialogStore,
    certificateStore: {
      loadCertificates,
      certificatesLoading,
      certificates,
      searchRecords,
      searchOngoing,
      setSearchOngoing,
      error,
      totalCertificates,
    },
    navigationStore,
  } = useStore();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_RECORDS_PAGE_SIZE);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    if (searchValue) {
      search(searchValue, newPage);
    } else {
      loadCertificates(rowsPerPage, newPage * rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (searchValue) {
      search(searchValue, 0, newRowsPerPage);
    } else {
      loadCertificates(newRowsPerPage, 0);
    }
  };

  const [searchValue, setSearchValue] = useState('');

  const debouncedSearch = useRef(
    debounce(
      (val: string, limit: number, skip: number) =>
        searchRecords(val, limit, skip),
      1000
    )
  ).current;

  useEffect(() => {
    if (userStore.loggedIn) {
      loadCertificates(DEFAULT_RECORDS_PAGE_SIZE, 0);
    }
  }, [userStore.loggedIn, loadCertificates]);

  const openNewRecordForm = () => {
    navigationStore.push(certNewPath);
  };

  const openCertificate = (id: string, formId: string) => {
    window.open(`${window.location.origin}${certificatePath}?id=${id}&form=${formId}`, '_blank');
  };

  const openRecordDetails = (id: string) => {
    window.open(`${window.location.origin}${recordsPath}/${id}`, '_blank');
  };

  const search = (query: string, page = 0, rows?: number) => {
    rows = rows || rowsPerPage;
    setSearchOngoing(true);
    setPage(page);
    setSearchValue(query);
    debouncedSearch(query, rows, page * rows);
  };

  const showQR = (id: string, formId: string) => {
    dialogStore.setDialogConfig({
      open: true,
      onClose: dialogStore.closeDialog,
      onCancel: dialogStore.closeDialog,
      onConfirm: dialogStore.closeDialog,
      title: t('certificate.table.shareQRTitle'),
      component: () => (
        <QRCode
          value={`${window.location.origin}${certificatePath}?id=${id}&form=${formId}`}
          includeMargin={true}
          size={256}
        />
      ),
      singleButtonAction: dialogStore.closeDialog,
      singleButtonText: t('common:button.close'),
    });

  }

  if (!userStore.loggedIn) {
    return <LoginRequired />;
  }

  if (error) {
    return <ErrorInfoBox title={error.title} subtitle={error.message} />;
  }

  return (
    <div className={classes.wrapper}>
      {certificatesLoading ? (
        <div className={classes.verticalAlign}>
          <CircularProgress color={'primary'} />
        </div>
      ) : (
        <>
          {certificates.length || searchValue || searchOngoing ? (
            <>
              <div className={classes.topSection}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addRecordButton}
                  onClick={openNewRecordForm}
                >
                  {t('certificate.new.button')}
                </Button>

                <TextField
                  color="primary"
                  classes={{ root: classes.searchBarRoot }}
                  label={t('certificate.table.search')}
                  type="text"
                  variant="outlined"
                  size={'small'}
                  value={searchValue}
                  onChange={(event) => search(event.target.value)}
                />
              </div>

              {searchOngoing ? (
                <CircularProgress color="primary" />
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">{t('certificate.table.mainProperty')}</TableCell>
                          <TableCell align="center">
                            {t('record.id')}
                          </TableCell>
                          {matchesSmallScreen ? null : (
                            <TableCell align="center">
                              {t('certificate.table.date')}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {t('certificate.table.formId')}
                          </TableCell>
                          <TableCell align="center">
                            {t('certificate.table.share')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {certificates.map((record) => (
                          <TableRow
                            key={record.id}
                          >
                            <TableCell
                              align="center"
                              style={{ cursor: 'pointer' }}
                              onClick={() => openCertificate(record.id, record.formId)}
                            >
                              {matchesSmallScreen
                                ? shortenTableString(record.mainProperty)
                                : record.mainProperty}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: 'pointer' }}
                              onClick={() => openRecordDetails(record.id)}
                              className={classes.nameCell}
                            >
                              <span>{record.id}</span>
                            </TableCell>

                            {matchesSmallScreen ? null : (
                              <TableCell align="center">
                                {record.creationTime ? new Date(record.creationTime).toLocaleDateString()  : '-'}
                              </TableCell>
                            )}
                            <TableCell align="center">
                              {record.formId}
                            </TableCell>
                            <TableCell
                              style={{ cursor: 'pointer' }}
                              onClick={() => showQR(record.id, record.formId)}
                              align="center"
                            >
                              <ScanQRIcon />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    style={{ width: '100%' }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCertificates}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </>
          ) : (
            <div className={classes.verticalAlign}>
              <InfoBox
                title={t('certificate.error.empty.title')}
                subtitle={t('certificate.error.empty.subtitle')}
                leftButton={{
                  variant: 'contained',
                  onClick: openNewRecordForm,
                  text: t('certificate.new.button'),
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default observer(CertificateList);
