import i18next from 'i18next';
import { makeAutoObservable } from 'mobx';
import { IDialogConfig } from '../models/dialogConfig';

export default class DialogStore {
  dialogConfig: IDialogConfig = {
    open: false,
    onClose: () => {},
    onCancel: () => {},
    onConfirm: () => Promise.resolve(),
    title: '',
    component: null,
    text: '',
    cancelButtonText: i18next.t('common:button.cancel'),
    confirmButtonText: i18next.t('common:button.confirm'),
  };

  constructor() {
    makeAutoObservable(this);
  }

  setDialogConfig = (dialogConfig: IDialogConfig) => {
    if (!dialogConfig.cancelButtonText)
      dialogConfig.cancelButtonText = i18next.t('common:button.cancel');
    if (!dialogConfig.confirmButtonText)
      dialogConfig.confirmButtonText = i18next.t('common:button.confirm');
    this.dialogConfig = dialogConfig;
  };

  openDialog = () => {
    this.dialogConfig.open = true;
  };

  closeDialog = () => {
    this.dialogConfig.open = false;
  };
}
