import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import env from '@beam-australia/react-env';

const fallBackLng = ['en', 'de'];

if (
  process.env.NODE_ENV === 'development' ||
  env('NOTRZR_ENV') === 'development'
) {
  fallBackLng.push('cimode');
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug:
      process.env.NODE_ENV === 'development' ||
      env('NOTRZR_ENV') === 'development',
    fallbackLng: fallBackLng,
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
