import {
  Button,
  CircularProgress,
  Collapse,
  Container,
  createStyles,
  makeStyles,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {fileSave} from 'browser-fs-access';
import forge from 'node-forge';
import axios from 'axios';
import EthCrypto from 'eth-crypto';
import {useStore} from '../../common/stores/store';
import theme, {ITheme} from '../../common/theme';
import clsx from 'clsx';
import {
  AttachFile,
  Bookmark,
  BookmarkBorder,
  Cancel,
  CheckCircle,
  ExpandLess,
  ExpandMore,
  InsertDriveFileOutlined,
} from '@material-ui/icons';
import {IRecord, IRecordLink, RecordLinkType} from '../../common/models/record';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import api from '../../common/api';
import {IRequestBody, ITimestamp} from '../../common/api/http';
import {decryptData} from '../../utils/crypto';
import AuthorizationQr from './AuthorizationQr';
import {useTranslation} from 'react-i18next';

import {ReactComponent as LinkFrom} from '../../common/icons/link_from.svg';
import {ReactComponent as LinkTo} from '../../common/icons/link_to.svg';
import {ReactComponent as LinkSignature} from '../../common/icons/link_signature.svg';
import {ReactComponent as LinkInvitation} from '../../common/icons/link_invitation.svg';
import {recordsPath} from '../../common/constants/routes';
import {Skeleton} from '@material-ui/lab';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    formWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    formGroupWrapper: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    fileDownloadWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: '100%',
      height: '73px',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
    downloadText: {
      textOverflow: 'ellipsis',
      maxHeight: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 120,
      flex: 1,
    },
    popover: {
      marginLeft: theme.spacing(1),
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    formControl: {
      marginBottom: theme.spacing(2),
      width: '100%',
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '6px',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      '& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.75)',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    bookmarkButton: {
      textTransform: 'capitalize',
      [theme.breakpoints.up('md')]: {
        width: '20%',
      },
    },
    tableButton: {
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '6px',
      justifyContent: 'flex-start',
      textTransform: 'capitalize',
    },
    tableButtonIcon: {
      marginLeft: 'auto',
    },
    tableButtonExpanded: {
      borderRadius: '6px 6px 0 0',
      boxShadow: 'none',
    },
    tableEmpty: {
      padding: '1rem',
      textAlign: 'center',
    },
    timestampField: {
      [theme.breakpoints.up('md')]: {
        width: '20%',
      },
    },
    urlField: {
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
      cursor: 'pointer',
    },
    checkIcon: {
      width: '10%',
      alignSelf: 'center',
      fontSize: '3.5rem',
      cursor: 'pointer',
    },
    formLabel: {
      padding: theme.spacing(1),
    },
    activateButton: {
      alignSelf: 'flex-start',
      ...theme.mixins.defaultButton,
      ...theme.mixins.wideButton,
    },
    table: {
      minWidth: 480,
    },
    idCell: {
      ...theme.mixins.overflowEllipsis,
      width: '300px',
      maxWidth: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '150px',
        maxWidth: '150px',
      },
    },
    nameCell: {
      ...theme.mixins.overflowEllipsis,
      maxWidth: '180px',
      [theme.breakpoints.down('xs')]: {
        width: '150px',
        maxWidth: '150px',
      },
    },
    walletButtonLabel: {
      ...theme.mixins.overflowEllipsis,
      display: 'block',
    },
  })
);

const RecordDetailsForm = () => {
  const { t } = useTranslation([
    'records',
    'bookmarks',
    'authorizations',
    'common',
  ]);
  const classes = useStyles();
  const {
    authorizationStore,
    recordStore: {
      selectedRecord,
      toggleBookmark,
      recordDetailsLoading,
      links,
      loadRecordLinks,
      linksLoading,
    },
    userStore,
    dialogStore,
    walletStore,
    localWalletStore,
    navigationStore,
  } = useStore();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [isHoveringDownload, setIsHoveringDownload] = useState(false);
  const [firstLinksLoad, setFirstLinksLoad] = useState(true);
  const [showRecordMetadataTable, setShowRecordMetadataTable] = useState(false);
  const [showAttachmentMetadataTable, setShowAttachmentMetadataTable] =
    useState(false);
  const [showLinkTable, setShowLinkTable] = useState(false);
  const [showSignatureTable, setShowSignatureTable] = useState(false);
  const downloadTextRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!selectedRecord?.file) {
      setShowDownload(false);
      return;
    }
    const foundWallet = walletStore.wallets.find(
      (wallet) =>
        wallet.ethereumAddress.toLowerCase() ===
        selectedRecord?.author.toLowerCase()
    );
    setShowDownload(Boolean(foundWallet));
  }, [walletStore.wallets, selectedRecord?.author, selectedRecord?.file]);

  useEffect(() => {
    if (selectedRecord && userStore.loggedIn) {
      loadRecordLinks(selectedRecord.id);
    }
    setFirstLinksLoad(false);
  }, [selectedRecord, loadRecordLinks, userStore.loggedIn]);

  const getLinkTypeIcon = (type: RecordLinkType) => {
    switch (type) {
      case RecordLinkType.FROM:
        return <LinkFrom />;
      case RecordLinkType.TO:
        return <LinkTo />;
      case RecordLinkType.INVITATION:
        return <LinkInvitation style={{ width: 24, height: 24 }} />;
      case RecordLinkType.SIGNATURE:
        return <LinkSignature />;
      default:
        return 'Unknown';
    }
  };

  const shortenTableString = (s: string) =>
    s ? s.slice(0, 5) + '...' + s.slice(-3) : '-';

  const openBookmarkDialog = () => {
    if (userIsAuthor()) {
      dialogStore.setDialogConfig({
        open: true,
        onClose: dialogStore.closeDialog,
        onCancel: dialogStore.closeDialog,
        onConfirm: toggleBookmark,
        title: t('bookmarks:remove_bookmark.title'),
        text: t('bookmarks:remove_bookmark.description'),
      });
    } else {
      toggleBookmark();
    }
  };

  const openRecordDetails = (id: string) => {
    navigationStore.push(`${recordsPath}/${id}`);
  };

  const userIsAuthor = () => {
    if (!selectedRecord) return false;

    return walletStore.wallets.find(
      (wallet) =>
        wallet.ethereumAddress.toLowerCase() ===
        selectedRecord.author.toLowerCase()
    );
  };

  const openMatchingHashesDialog = () => {
    dialogStore.setDialogConfig({
      open: true,
      onClose: dialogStore.closeDialog,
      onCancel: dialogStore.closeDialog,
      onConfirm: dialogStore.closeDialog,
      title: t('hash_check.match.title'),
      text: t('hash_check.match.description'),
      singleButtonAction: dialogStore.closeDialog,
      singleButtonText: t('common:button.close'),
    });
  };

  const openMismatchingHashesDialog = useCallback(() => {
    dialogStore.setDialogConfig({
      open: true,
      onClose: dialogStore.closeDialog,
      onCancel: dialogStore.closeDialog,
      onConfirm: dialogStore.closeDialog,
      title: t('hash_check.no_match.title'),
      text: t('hash_check.no_match.description'),
      singleButtonAction: dialogStore.closeDialog,
      singleButtonText: t('common:button.close'),
    });
  }, [dialogStore, t]);

  const closeAndRefetchAuthorization = () => {
    authorizationStore.fetchAuthorization();
    dialogStore.closeDialog();
  };

  const showQRCode = () => {
    dialogStore.setDialogConfig({
      open: true,
      onClose: closeAndRefetchAuthorization,
      onCancel: closeAndRefetchAuthorization,
      onConfirm: closeAndRefetchAuthorization,
      title: t('authorizations:qr_scan.title'),
      component: () => (
        <AuthorizationQr
          publicKey={
            localWalletStore.wallet ? localWalletStore.wallet.publicKey : ''
          }
          address={
            localWalletStore.wallet ? localWalletStore.wallet.address : ''
          }
        />
      ),
      singleButtonAction: closeAndRefetchAuthorization,
      singleButtonText: t('common:button.close'),
    });
  };

  const startDownload = () => {
    if (!showDownload) {
      return;
    }
    if (
      !authorizationStore.authorization &&
      !authorizationStore.authorizationLoading
    ) {
      showQRCode();
      return;
    }
    downloadFile();
  };

  const downloadFile = async () => {
    setDownloadInProgress(true);
    if (selectedRecord && authorizationStore.authorization) {
      const timestamp = Date.now();
      const value: ITimestamp = {
        timestamp,
      };
      const message = JSON.stringify(value);
      const signature = await localWalletStore.wallet?.signMessage(message);
      const payload: IRequestBody<ITimestamp> = {
        value,
        signedMessage: {
          signature: signature || '',
          address: localWalletStore.wallet?.address || '',
        },
      };
      let encryptedFile;
      try {
        encryptedFile = await api.Repositories.download(
          selectedRecord.author,
          selectedRecord.id,
          payload
        );
      } catch (err) {
        setDownloadInProgress(false);
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          showQRCode();
          return;
        }
        throw err;
      }
      const encryptedFileArray = await encryptedFile.data.arrayBuffer();
      const iv = forge.util.createBuffer(encryptedFileArray.slice(0, 12));
      const ret = EthCrypto.cipher.parse(authorizationStore.authorization.key);
      const fileKeyFromWallet = await EthCrypto.decryptWithPrivateKey(
        localWalletStore.wallet?.privateKey || '',
        ret
      );
      const tag = forge.util.createBuffer(encryptedFileArray.slice(12, 28));
      const encryptedData = encryptedFileArray.slice(28);
      const decryptedData = decryptData(
        forge.util.createBuffer(encryptedData).getBytes(),
        forge.util.hexToBytes(fileKeyFromWallet.slice(2)),
        iv,
        tag
      );
      if (decryptedData) {
        await fileSave(
          new Blob([Buffer.from(decryptedData.output.getBytes(), 'binary')], {
            type: selectedRecord.file?.type,
          }),
          {
            fileName: selectedRecord.file?.name,
            id: 'recordSave',
          }
        );
      }
    }
    setDownloadInProgress(false);
  };

  const hashesMatch = ({ documentDbHash, blockchainHash }: IRecord) =>
    documentDbHash === blockchainHash;

  useEffect(() => {
    if (
      !recordDetailsLoading &&
      selectedRecord &&
      !hashesMatch(selectedRecord)
    ) {
      openMismatchingHashesDialog();
    }
  }, [recordDetailsLoading, selectedRecord, openMismatchingHashesDialog]);

  const openUriDialog = (event: React.MouseEvent<HTMLDivElement>) => {
    dialogStore.setDialogConfig({
      open: true,
      onClose: dialogStore.closeDialog,
      onCancel: dialogStore.closeDialog,
      onConfirm: openUri,
      title: t('open_url.title'),
      text: t('open_url.description'),
      confirmButtonText: t('common:button.continue'),
      cancelButtonText: t('common:button.cancel'),
    });
  };

  const openUri = () => {
    dialogStore.closeDialog();
    window.open(selectedRecord?.file?.uri, '_blank');
  };

  const signaturesList = useMemo(() => Object.values((links?.filter(
    (link) =>
      link.type === RecordLinkType.INVITATION ||
      link.type === RecordLinkType.SIGNATURE
  ) || []).reduce((accum, val) => ({
    ...accum,
    [val.id]: accum[val.id]?.type === RecordLinkType.SIGNATURE
      ? accum[val.id]
      : val
  }), {} as Record<string, IRecordLink>)), [links]);

  const linksList = useMemo(() => [
    ...(links?.filter(
      (link) =>
        link.type === RecordLinkType.INVITATION ||
        link.type === RecordLinkType.SIGNATURE
    ) || []).map((v) => ({
      ...v,
      type: v.type === RecordLinkType.SIGNATURE
        ? RecordLinkType.FROM
        : RecordLinkType.TO
    })),
    ...links?.filter(
      (link) =>
        link.type === RecordLinkType.FROM || link.type === RecordLinkType.TO
    ) || []], [links]);

  if (!selectedRecord) return null;

  const {
    name,
    id,
    author,
    documentDbHash,
    blockchainHash,
    data,
    bookmark,
    timestamp,
    onCreated,
    file,
  } = selectedRecord;

  const BookmarkButton = !userStore.loggedIn ? (
    <Button
      className={clsx(classes.formControl, classes.bookmarkButton)}
      startIcon={<BookmarkBorder style={{ fontSize: '2rem' }} />}
      disabled
    >
      Bookmark
    </Button>
  ) : bookmark ? (
    <Button
      color="primary"
      variant="contained"
      className={clsx(classes.formControl, classes.bookmarkButton)}
      startIcon={<Bookmark style={{ fontSize: '2rem' }} />}
      onClick={openBookmarkDialog}
    >
      {t('button.bookmark')}
    </Button>
  ) : (
    <Button
      color="primary"
      className={clsx(classes.formControl, classes.bookmarkButton)}
      startIcon={<BookmarkBorder style={{ fontSize: '2rem' }} />}
      onClick={toggleBookmark}
    >
      {t('button.bookmark')}
    </Button>
  );

  return (
    <form className={classes.formWrapper} autoComplete="off">
      <div className={classes.formGroupWrapper}>
        <TextField
          color="primary"
          size={'small'}
          value={name}
          label={t('record.name')}
          disabled={true}
          variant="standard"
          className={clsx(classes.formControl)}
          InputLabelProps={{
            className: classes.formLabel,
          }}
        />
        <TextField
          color="primary"
          size={'small'}
          value={new Date(onCreated || timestamp).toLocaleString()}
          label={t('record.timestamp')}
          disabled={true}
          variant="standard"
          className={clsx(classes.formControl, classes.timestampField)}
          InputLabelProps={{
            className: classes.formLabel,
          }}
        />
        {BookmarkButton}
      </div>

      <div className={classes.formGroupWrapper}>
        <TextField
          InputLabelProps={{
            className: classes.formLabel,
          }}
          disabled={true}
          label={t('record.text')}
          value={data || ''}
          multiline
          size={'small'}
          rows={20}
          className={clsx(classes.formControl)}
        />
      </div>
      {Boolean(selectedRecord.file) && (
        <div className={classes.formGroupWrapper}>
          {Boolean(selectedRecord?.file) && <div className={classes.fileDownloadWrapper} onClick={startDownload}>
            <AttachFile
              fontSize={'large'}
              onMouseEnter={() => setIsHoveringDownload(true)}
              onMouseLeave={() => setIsHoveringDownload(false)}
              style={{
                cursor: showDownload ? 'pointer' : 'default',
                color: showDownload
                  ? theme.palette.primary.main
                  : theme.palette.grey[500],
              }}
            />
            <Typography
              ref={downloadTextRef}
              onMouseEnter={() => setIsHoveringDownload(true)}
              onMouseLeave={() => setIsHoveringDownload(false)}
              variant={'h6'}
              className={classes.downloadText}
              style={{
                cursor: showDownload ? 'pointer' : 'default',
                color: showDownload
                  ? theme.palette.primary.main
                  : theme.palette.grey[500],
              }}
            >
              {t('record.download.text', {
                filename: selectedRecord?.file?.name || '',
              })}
            </Typography>
            <Popover
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              anchorEl={downloadTextRef.current}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              open={!showDownload && isHoveringDownload}
            >
              {t('record.download.only_author_popover')}
            </Popover>
            {downloadInProgress && (
              <CircularProgress
                color={'primary'}
                size="2rem"
                style={{marginRight: '0.5rem'}}
              />
            )}
          </div>}
          {file?.uri && (
            <div
              className={clsx(classes.formControl, classes.urlField)}
              onClick={openUriDialog}
            >
              <div
                className={
                  'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-shrink MuiInputLabel-marginDense Mui-disabled Mui-disabled MuiFormLabel-filled'
                }
              >
                {t('record.url')}
              </div>
              <div
                className={
                  'MuiInputBase-root MuiInput-root MuiInput-underline Mui-disabled Mui-disabled MuiInputBase-formControl MuiInput-formControl MuiInputBase-marginDense MuiInput-marginDense'
                }
                style={{
                  paddingBottom: 7,
                  width: '100%',
                  cursor: 'pointer',
                  wordBreak: 'break-all'
                }}
              >
                {file?.uri}
              </div>
            </div>
          )}
        </div>
      )}
      <div>
        <Button
          color="primary"
          variant="contained"
          endIcon={showRecordMetadataTable ? <ExpandLess /> : <ExpandMore />}
          fullWidth
          classes={{
            endIcon: classes.tableButtonIcon,
          }}
          className={clsx(
            classes.tableButton,
            showRecordMetadataTable && classes.tableButtonExpanded
          )}
          onClick={() => setShowRecordMetadataTable(!showRecordMetadataTable)}
        >
          {t('button.record_metadata')}
        </Button>
        <Collapse in={showRecordMetadataTable} timeout="auto" unmountOnExit>
          <Container component={Paper} maxWidth={false}>
            <div className={classes.formGroupWrapper}>
              <TextField
                color="primary"
                size={'small'}
                value={id}
                label={t('record.id')}
                disabled={true}
                variant="standard"
                className={clsx(classes.formControl)}
                InputLabelProps={{
                  className: classes.formLabel,
                }}
              />
              <TextField
                color="primary"
                size={'small'}
                value={author}
                label={t('record.author')}
                disabled={true}
                variant="standard"
                className={clsx(classes.formControl)}
                InputLabelProps={{
                  className: classes.formLabel,
                }}
              />
            </div>
            <div className={classes.formGroupWrapper}>
              <TextField
                color="primary"
                size={'small'}
                value={documentDbHash}
                label={t('record.data_hash')}
                disabled={true}
                variant="standard"
                className={clsx(classes.formControl)}
                InputLabelProps={{
                  className: classes.formLabel,
                }}
              />

              {hashesMatch(selectedRecord) ? (
                <CheckCircle
                  className={clsx(classes.checkIcon)}
                  color="primary"
                  onClick={openMatchingHashesDialog}
                />
              ) : (
                <Cancel
                  className={clsx(classes.checkIcon)}
                  color="primary"
                  onClick={openMismatchingHashesDialog}
                />
              )}

              <TextField
                color="primary"
                size={'small'}
                value={blockchainHash}
                label={t('record.blockchain_hash')}
                disabled={true}
                variant="standard"
                className={clsx(classes.formControl)}
                InputLabelProps={{
                  className: classes.formLabel,
                }}
              />
            </div>
          </Container>
        </Collapse>
      </div>
      {file && (
        <div style={{ marginTop: '1rem' }}>
          <Button
            color="primary"
            variant="contained"
            endIcon={
              showAttachmentMetadataTable ? <ExpandLess /> : <ExpandMore />
            }
            fullWidth
            classes={{
              endIcon: classes.tableButtonIcon,
            }}
            className={clsx(
              classes.tableButton,
              showAttachmentMetadataTable && classes.tableButtonExpanded
            )}
            onClick={() =>
              setShowAttachmentMetadataTable(!showAttachmentMetadataTable)
            }
          >
            {t('button.attachment_metadata')}
          </Button>
          <Collapse
            in={showAttachmentMetadataTable}
            timeout="auto"
            unmountOnExit
          >
            <Container component={Paper} maxWidth={false}>
              <div className={classes.formGroupWrapper}>
                <TextField
                  color="primary"
                  size={'small'}
                  value={file.type}
                  label={t('record.filetype')}
                  disabled={true}
                  variant="standard"
                  className={clsx(classes.formControl)}
                  InputLabelProps={{
                    className: classes.formLabel,
                  }}
                />
                <TextField
                  color="primary"
                  size={'small'}
                  value={file.size ? file.size : t('common:not_available')}
                  label={t('record.size')}
                  disabled={true}
                  variant="standard"
                  className={clsx(classes.formControl)}
                  InputLabelProps={{
                    className: classes.formLabel,
                  }}
                />
              </div>
              <div className={classes.formGroupWrapper}>
                <TextField
                  color="primary"
                  size={'small'}
                  value={file?.hash}
                  label={t('record.hash')}
                  disabled={true}
                  variant="standard"
                  className={clsx(classes.formControl)}
                  InputLabelProps={{
                    className: classes.formLabel,
                  }}
                />
                <TextField
                  color="primary"
                  size={'small'}
                  value={file?.method}
                  label={t('record.hashtype')}
                  disabled={true}
                  variant="standard"
                  className={clsx(classes.formControl)}
                  InputLabelProps={{
                    className: classes.formLabel,
                  }}
                />
              </div>
            </Container>
          </Collapse>
        </div>
      )}
      <div style={{ marginTop: '1rem' }}>
        <Button
          color="primary"
          variant="contained"
          endIcon={showLinkTable ? <ExpandLess /> : <ExpandMore />}
          fullWidth
          classes={{
            endIcon: classes.tableButtonIcon,
          }}
          className={clsx(
            classes.tableButton,
            showLinkTable && classes.tableButtonExpanded
          )}
          onClick={() => setShowLinkTable(!showLinkTable)}
        >
          {t('button.links')}
        </Button>
        <Collapse in={showLinkTable} timeout="auto" unmountOnExit>
          <TableContainer component={Paper}>
            {firstLinksLoad || linksLoading ? (
              <Table className={classes.table} aria-label="Links Table Loading">
                <TableBody>
                  <TableRow key={'skeleton'} style={{ padding: '16px' }}>
                    <TableCell style={{ width: '2rem' }} align="center">
                      <Skeleton width="2rem" />
                    </TableCell>
                    <TableCell align="center" style={{ width: '380px' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell align="left" className={classes.nameCell}>
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center" style={{ width: '2rem' }}>
                      <Skeleton width="2rem" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : !userStore.loggedIn ? (
              <div className={classes.tableEmpty}>{t('table.login.links')}</div>
            ) : linksList.length > 0 ? (
              linksList.map((link) => (
                <Table className={classes.table} aria-label="Links Table">
                  <TableBody>
                    <TableRow
                      key={link.id}
                      onClick={() => {
                        openRecordDetails(link.id);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell align="left" style={{ width: '2rem' }}>
                        <InsertDriveFileOutlined
                          style={{
                            fontSize: '2rem',
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.idCell}>
                        {matchesSmallScreen
                          ? shortenTableString(link.id)
                          : link.id}
                      </TableCell>
                      <TableCell align="left" className={classes.nameCell}>
                        <span>{link.name} </span>
                      </TableCell>
                      <TableCell align="right" style={{width: '2rem'}}>
                        <span>{getLinkTypeIcon(link.type)} </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))
            ) : (
              <div className={classes.tableEmpty}>{t('table.empty.links')}</div>
            )}
          </TableContainer>
        </Collapse>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          color="primary"
          variant="contained"
          endIcon={showSignatureTable ? <ExpandLess /> : <ExpandMore />}
          fullWidth
          classes={{
            endIcon: classes.tableButtonIcon,
          }}
          className={clsx(
            classes.tableButton,
            showSignatureTable && classes.tableButtonExpanded
          )}
          onClick={() => setShowSignatureTable(!showSignatureTable)}
        >
          {t('button.signatures')}
        </Button>
        <Collapse in={showSignatureTable} timeout="auto" unmountOnExit>
          <TableContainer component={Paper}>
            {firstLinksLoad || linksLoading ? (
              <Table
                className={classes.table}
                aria-label="Signatures table loading"
              >
                <TableBody>
                  <TableRow key={'skeleton'} style={{ padding: '16px' }}>
                    <TableCell style={{ width: '2rem' }} align="center">
                      <Skeleton width="2rem" />
                    </TableCell>
                    <TableCell align="center" style={{ width: '380px' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell align="left" className={classes.nameCell}>
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center" style={{ width: '2rem' }}>
                      <Skeleton width="2rem" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : !userStore.loggedIn ? (
              <div className={classes.tableEmpty}>
                {t('table.login.signatures')}
              </div>
            ) : signaturesList.length > 0 ? (
              signaturesList.map((link) => (
                <Table className={classes.table} aria-label="Signatures table">
                  <TableBody>
                    <TableRow
                      key={link.id}
                      onClick={() => {
                        openRecordDetails(link.id);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell align="left" style={{ width: '2rem' }}>
                        <InsertDriveFileOutlined
                          style={{
                            fontSize: '2rem',
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.idCell}>
                        {matchesSmallScreen
                          ? shortenTableString(link.id)
                          : link.id}
                      </TableCell>
                      <TableCell align="left" className={classes.nameCell}>
                        <span>{link.name} </span>
                      </TableCell>
                      <TableCell align="right" style={{ width: '2rem' }}>
                        <span>{getLinkTypeIcon(link.type)} </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))
            ) : (
              <div className={classes.tableEmpty}>
                {t('table.empty.signatures')}
              </div>
            )}
          </TableContainer>
        </Collapse>
      </div>
    </form>
  );
};

export default observer(RecordDetailsForm);
