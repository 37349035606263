import {
  CircularProgress,
  makeStyles,
  createStyles,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import QRCode from 'qrcode.react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../common/stores/store';
import { ITheme } from '../../common/theme';

const msBeforeHide = 600000;
let clearTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    wrapper: { ...theme.mixins.wrapper },
    confirmButton: {
      marginTop: theme.spacing(2),
    },
  })
);

const WalletQr = () => {
  const { t } = useTranslation(['wallets']);
  const classes = useStyles();
  const {
    walletStore: { newWalletData, loadingNewWalletData, setNewWalletData },
  } = useStore();

  useEffect(() => {
    if (newWalletData) {
      const hideQrCode = () => {
        setNewWalletData(null);
      };
      clearTimer = setTimeout(hideQrCode, msBeforeHide);
      return () => clearTimeout(clearTimer);
    }
  }, [newWalletData, setNewWalletData]);

  if (loadingNewWalletData) {
    return <CircularProgress color="primary" />;
  } else if (newWalletData) {
    return (
      <div className={classes.wrapper}>
        <QRCode
          value={newWalletData.serializedValue}
          includeMargin={true}
          size={256}
        />
        <Typography color="primary" align="center">
          {t('qr_dialog.scan_explanation')}
        </Typography>
      </div>
    );
  }
  return null;
};

export default observer(WalletQr);
