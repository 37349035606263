const getItem = (key: string): string | null => {
  return localStorage.getItem(key);
};

const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

const storage = {
  getItem,
  setItem,
  removeItem,
};

export default storage;

export const storageKeys = {
  POST_IS_REDIRECT_URL: 'POST_IS_REDIRECT_URL',
  COOKIE_POLICY_ACCEPTED: 'COOKIE_POLICY_ACCEPTED',
  LOCAL_WALLET: 'LOCAL_WALLET',
  DRAFT_RECORD: 'DRAFT_RECORD',
  DEFAULT_WALLET: 'DEFAULT_WALLET',
};
