import i18next from 'i18next';
import { makeAutoObservable } from 'mobx';
import api from '../api';
import { IError } from '../models/error';
import {
  DEFAULT_RECORDS_PAGE_SIZE,
  ICreateRecord,
  IFormState,
  IRecord,
  IRecordLink,
} from '../models/record';
import { IRepository } from '../models/repositories';
import errorNotify from '../utils/notifications/errorNotify';
import storage, { storageKeys } from '../utils/storage';

export default class RecordStore {
  records: IRecord[] = [];
  totalRecords = 0;
  recordsLoading = false;
  recordDetailsLoading = false;
  fileToUpload: File | null = null;
  searchOngoing = false;
  selectedRecord: IRecord | null = null;
  pendingRecord: IRecord | null = null;
  pendingRecordLoading = false;
  formState: IFormState = {
    selectedWalletIdx: 0,
    recordName: '',
    recordText: '',
    recordUrl: '',
  };
  links: IRecordLink[] = [];
  linksLoading = false;
  error: IError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError = (error: IError | null) => {
    this.error = error;
  };

  setRecords = (records: IRecord[]) => {
    this.records = records;
  };

  setTotalRecords = (totalCount: number) => {
    this.totalRecords = totalCount;
  };

  setSelectedRecord = (record: IRecord | null) => {
    this.selectedRecord = record;
  };

  setPendingRecord = (record: IRecord | null) => {
    this.pendingRecord = record;
  };

  setRecordsLoading = (recordsLoading: boolean) => {
    this.recordsLoading = recordsLoading;
  };

  setRecordDetailsLoading = (recordDetailsLoading: boolean) => {
    this.recordDetailsLoading = recordDetailsLoading;
  };

  setPendingRecordLoading = (pendingRecordLoading: boolean) => {
    this.pendingRecordLoading = pendingRecordLoading;
  };

  setSearchOngoing = (searchOngoing: boolean) => {
    this.searchOngoing = searchOngoing;
  };

  setFileToUpload = (value: File | null) => {
    this.fileToUpload = value;
  };

  getFormState = () => {
    const savedFormState = storage.getItem(storageKeys.DRAFT_RECORD);
    if (savedFormState) {
      this.formState = JSON.parse(savedFormState);
    }
  };

  setFormState = (formState: IFormState) => {
    this.formState = formState;
    storage.setItem(storageKeys.DRAFT_RECORD, JSON.stringify(formState));
  };

  setLinks = (links: IRecordLink[]) => {
    this.links = links;
  };

  removeLinks = () => {
    this.links = [];
  };

  setLinksLoading = (linksLoading: boolean) => {
    this.linksLoading = linksLoading;
  };

  removeFormState = () => {
    this.formState = {
      selectedWalletIdx: 0,
      recordName: '',
      recordText: '',
      recordUrl: '',
    };
    storage.removeItem(storageKeys.DRAFT_RECORD);
  };

  loadRecords = async (limit = DEFAULT_RECORDS_PAGE_SIZE, skip = 0) => {
    try {
      this.setError(null);
      this.setRecordsLoading(true);
      const { value, meta } = await api.Records.list(limit, skip);
      this.setRecords(value);
      if (meta && meta.totalCount) {
        this.setTotalRecords(meta.totalCount);
      }
    } catch (error) {
      this.setError({
        title: i18next.t('title.error'),
        message: i18next.t('error.load_record'),
      });
    } finally {
      this.setRecordsLoading(false);
      this.setSearchOngoing(false);
    }
  };

  activateRecord = async (
    record: ICreateRecord | FormData
  ): Promise<IRecord | undefined> => {
    try {
      this.setRecordsLoading(true);
      const { value } = await api.Records.create(record);
      return value;
    } catch (error) {
      errorNotify(i18next.t('error.record_activation'));
    } finally {
      this.setRecordsLoading(false);
    }
  };

  uploadFile = async (
    address: string,
    recordId: string,
    data: FormData
  ): Promise<IRepository | undefined> => {
    try {
      this.setRecordsLoading(true);
      const { value } = await api.Repositories.upload(address, recordId, data);
      return value;
    } catch (error) {
      errorNotify(i18next.t('error.file_upload'));
    } finally {
      this.setRecordsLoading(false);
    }
  };

  details = async (id: string): Promise<IRecord | undefined> => {
    try {
      this.setError(null);
      this.setSelectedRecord(null);
      this.setRecordDetailsLoading(true);
      const { value } = await api.Records.details(id);
      this.setSelectedRecord(value);
      return value;
    } catch (error) {
      this.setError({
        title: i18next.t('title.error'),
        message: i18next.t('error.load_record'),
      });
    } finally {
      this.setRecordDetailsLoading(false);
    }
  };

  toggleBookmark = async () => {
    if (!this.selectedRecord) return;
    this.setRecordDetailsLoading(true);
    const { id, bookmark } = this.selectedRecord;

    try {
      if (bookmark) {
        await api.Bookmarks.delete(id);
      } else {
        await api.Bookmarks.create(id);
      }

      this.setSelectedRecord({
        ...this.selectedRecord,
        bookmark: !bookmark,
      });
    } catch (_err) {
      errorNotify(i18next.t('error.bookmark_update'));
    }
    this.setRecordDetailsLoading(false);
  };

  toggleBookmarkInList = (id: string) => {
    const foundRecord = this.records.find((record) => record.id === id);
    if (foundRecord) {
      foundRecord.bookmark = !foundRecord.bookmark;
    }
  };

  searchRecords = async (
    searchValue: string,
    limit = DEFAULT_RECORDS_PAGE_SIZE,
    skip = 0
  ) => {
    try {
      this.setSearchOngoing(true);
      const { value, meta } = await api.Records.list(limit, skip, searchValue);
      this.setRecords(value);
      if (meta && meta.totalCount) {
        this.setTotalRecords(meta.totalCount);
      }
    } catch (error) {
      errorNotify(i18next.t('error.record_search'));
    } finally {
      this.setSearchOngoing(false);
    }
  };

  getPendingRecord = async () => {
    try {
      this.setError(null);
      this.setPendingRecordLoading(true);
      const { value } = await api.Records.getPending();
      if (value.length) {
        this.setPendingRecord(value[0]);
      } else {
        if (this.pendingRecord) {
          setTimeout(() => {
            this.setPendingRecordLoading(false);
            this.setPendingRecord(null);
          }, 10000);
        } else {
          this.setPendingRecordLoading(false);
        }
      }
    } catch (error) {
      this.setPendingRecordLoading(false);
      this.setError({
        title: i18next.t('title.error'),
        message: i18next.t('error.pending_record'),
      });
    }
  };

  loadRecordLinks = async (id: string) => {
    this.setLinksLoading(true);
    try {
      this.setError(null);
      const { value } = await api.Records.getAssetLinks(id);
      this.setLinks(value);
    } catch (error) {
      errorNotify(i18next.t('error.record_links'));
    } finally {
      this.setLinksLoading(false);
    }
  };
}
