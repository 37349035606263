import { useEffect, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../common/stores/store';
import errorNotify from '../../common/utils/notifications/errorNotify';

const MAX_FILE_SIZE = 5000000;
export const MAX_FILE_SIZE_MB = MAX_FILE_SIZE / 1000000;

interface IFileUpload {
  fileInputRef: RefObject<HTMLInputElement>;
}
const FileUpload = ({ fileInputRef }: IFileUpload) => {
  const { t } = useTranslation();
  const { recordStore } = useStore();

  useEffect(() => {
    recordStore.setFileToUpload(null);
  }, [recordStore]);

  const handleFile = async () => {
    if (!fileInputRef || !fileInputRef.current) return;
    const files = Array.from(fileInputRef.current.files || []);
    const fileCount = files.length;
    if (fileCount !== 1) return;
    const file = files[0];
    if (file.size > MAX_FILE_SIZE) {
      errorNotify(t('error.file_too_large', { maxFileSize: MAX_FILE_SIZE_MB }));
      return;
    }
    recordStore.setFileToUpload(file);
  };

  return (
    <input
      ref={fileInputRef}
      type="file"
      id="file-input"
      style={{ display: 'none' }}
      onChange={handleFile}
    />
  );
};

export default FileUpload;
