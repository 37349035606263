import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from '../../common/theme';
import { useStore } from '../../common/stores/store';
import { searchPath } from '../../common/constants/routes';
import React, { useState } from 'react';
import { IAppConfig, IThemeLogo } from '../../common/models/appConfig';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    logo: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '2rem',
      cursor: 'pointer',
      maxWidth: '100%',
      maxHeight: '52px'
    },
    appbarLogoWrapper: {
      marginLeft: `-${theme.spacing(6)}px`,
      marginRight: 'auto',
    },
    logoLeft: {
      color: theme.palette.primary.main,
    },
    logoRight: {
      color: theme.palette.text.primary,
    },
    sidebarLogoWrapper: {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
  })
);
interface IProps {
  inSidebar: boolean;
  redirectTo?: string;
}
const Logo = ({ inSidebar, redirectTo }: IProps) => {
  const classes = useStyles();
  const { configStore, navigationStore } = useStore();
  const [logo, setLogo] = useState<IThemeLogo>({
    type: 'text',
    altText: 'Logo',
    left: 'NOT',
    right: 'RZR',
  });
  const [renderLogo, setRenderLogo] = useState(false);
  React.useEffect(() => {
    const getCustomLogo = async () => {
      try {
        const customTheme: IAppConfig = await configStore.getAppConfig();
        if (customTheme.logo) {
          setLogo({ ...customTheme.logo });
        }
      } catch {}
      setRenderLogo(true);
    };
    getCustomLogo();
  }, [configStore]);

  const gotoSearchPage = () => {
    navigationStore.push(redirectTo || searchPath);
  };

  if (renderLogo && logo.type) {
    if (logo.type === 'image') {
      return (
        <img
          src={`/${logo.url}`}
          alt={logo.altText}
          className={`${
            inSidebar ? classes.sidebarLogoWrapper : classes.appbarLogoWrapper
          } ${classes.logo}`}
          onClick={gotoSearchPage}
        />
      );
    } else if (logo.type === 'text') {
      return (
        <Typography
          className={`${
            inSidebar ? classes.sidebarLogoWrapper : classes.appbarLogoWrapper
          } ${classes.logo}`}
          variant="h1"
          noWrap
          onClick={gotoSearchPage}
        >
          <span className={classes.logoLeft}>{logo.left}</span>
          <span className={classes.logoRight}>{logo.right}</span>
        </Typography>
      );
    } else {
      return <span />;
    }
  } else {
    return <span />;
  }
};

export default Logo;
