import {
  Drawer,
  Divider,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Logo from '../Logo';
import {Android, Apple} from '@material-ui/icons';
import clsx from 'clsx';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {ITheme} from '../../common/theme';
import {useStore} from '../../common/stores/store';
import {observer} from 'mobx-react-lite';
import {matchPath} from 'react-router';
import {
  certificatesPath,
  certNewPath,
  certSearchPath,
  certWalletsPath,
  imprintUrl,
  newFormPath,
  recordsPath,
  searchPath,
  walletsPath,
} from '../../common/constants/routes';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import React from "react";

export const drawerWidth = 240;
const txAndroidAppLink =
  'https://play.google.com/store/apps/details?id=com.tradeapp.notrzr';
const txAppleAppLink = 'https://apps.apple.com/app/id1598568315';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    certificatesTitle: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    sidebarItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    sidebarItemText: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: '3px',
    },
    sidebarItemTextActive: {
      borderBottom: '3px solid',
      borderBottomColor: theme.palette.primary.main,
      paddingBottom: 0,
    },
    sidebarIcon: {
      minWidth: '42px',
      color: theme.palette.primary.main,
    },
    sidebarInnerContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    activeNavLink: {
      color: 'pink',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 'auto',
      padding: theme.spacing(2),
    },
    button: {
      ...theme.mixins.defaultButton,
      width: '164px',
      marginBottom: theme.spacing(2),
    },
  })
);

interface IProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const Sidebar = ({mobileOpen, handleDrawerToggle}: IProps): JSX.Element => {
  const {t} = useTranslation(['menu']);
  const classes = useStyles();
  const theme = useTheme();
  const {pathname} = useLocation();
  const {configStore, userStore} = useStore();

  const login = () => {
    userStore.login();
  };

  const logout = () => {
    userStore.logout();
  };

  const changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang);
  };

  const isMatchCurrentPath = (path: string, config?: { exact: boolean }) =>
    Boolean(
      matchPath(pathname, {
        path,
        exact: config?.exact || false,
      })
    );

  const isCertificatesSection = isMatchCurrentPath(certificatesPath);

  const drawer = (
    <div className={classes.sidebarInnerContainer}>
      <div className={classes.toolbar}>
        <Logo redirectTo={isCertificatesSection ? certificatesPath : undefined} inSidebar={true}/>
        {isCertificatesSection ?
          (<Typography
            variant="subtitle1"
            className={classes.certificatesTitle}
          >
            {t('menu.certificates')}
          </Typography>)
          : null}
      </div>
      <Divider/>
      <List>
        <Link component={RouterLink} to={isCertificatesSection ? certSearchPath : searchPath} underline={'none'}>
          <ListItem className={classes.sidebarItem} button key="Search">
            <ListItemIcon classes={{root: classes.sidebarIcon}}>
              <SearchIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: `${classes.sidebarItemText} ${
                  isMatchCurrentPath(searchPath) ||
                  isMatchCurrentPath(certSearchPath) ||
                  isMatchCurrentPath('/', {exact: true})
                    ? classes.sidebarItemTextActive
                    : ''
                }`,
              }}
              primary={t('menu.search')}
            />
          </ListItem>
        </Link>

        <Link component={RouterLink} to={isCertificatesSection ? certWalletsPath : walletsPath} underline={'none'}>
          <ListItem className={classes.sidebarItem} button key="Wallets">
            <ListItemIcon classes={{root: classes.sidebarIcon}}>
              <WalletIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: `${classes.sidebarItemText} ${
                  isMatchCurrentPath(walletsPath) || isMatchCurrentPath(certWalletsPath)
                    ? classes.sidebarItemTextActive
                    : ''
                }`,
              }}
              primary={t('menu.wallets')}
            />
          </ListItem>
        </Link>

        <Link component={RouterLink} to={isCertificatesSection ? certificatesPath : recordsPath} underline={'none'}>
          <ListItem className={classes.sidebarItem} button key="Records">
            <ListItemIcon classes={{root: classes.sidebarIcon}}>
              <FolderIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: `${classes.sidebarItemText} ${
                  isMatchCurrentPath(recordsPath) || isMatchCurrentPath(certificatesPath, { exact: true })
                    ? classes.sidebarItemTextActive
                    : ''
                }`,
              }}
              primary={isCertificatesSection ? t('menu.certificates') : t('menu.records')}
            />
          </ListItem>
        </Link>

        {isCertificatesSection ? <Link component={RouterLink} to={certNewPath} underline={'none'}>
          <ListItem className={classes.sidebarItem} button key="Records">
            <ListItemIcon classes={{root: classes.sidebarIcon}}>
              <AddIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: `${classes.sidebarItemText} ${
                  isMatchCurrentPath(certNewPath, {exact: true}) || isMatchCurrentPath(newFormPath, {exact: true})
                    ? classes.sidebarItemTextActive
                    : ''
                }`,
              }}
              primary={t('menu.newCertificate')}
            />
          </ListItem>
        </Link> : null}
      </List>

      <Box className={classes.buttonsContainer}>
        <Button
          className={clsx(classes.button)}
          variant={'outlined'}
          color="primary"
          startIcon={<Android/>}
          target="_blank"
          href={
            configStore.appConfig.config?.appLink?.android || txAndroidAppLink
          }
        >
          {t(window.location.hostname.includes('bcdocudemo') ? 'button.android.bcdocu' : 'button.android.notrzr')}
        </Button>

        <Button
          className={clsx(classes.button)}
          variant={'outlined'}
          color="primary"
          startIcon={<Apple/>}
          target="_blank"
          href={configStore.appConfig.config?.appLink?.apple || txAppleAppLink}
        >
          {t(window.location.hostname.includes('bcdocudemo') ? 'button.ios.bcdocu' : 'button.ios.notrzr')}
        </Button>

        {userStore.loggedIn ? (
          <Button
            className={classes.button}
            variant={'text'}
            color={'primary'}
            onClick={logout}
          >
            {t('common:button.logout')}
          </Button>
        ) : (
          <>
            <Button
              className={clsx(classes.button)}
              variant={'contained'}
              color={'primary'}
              onClick={login}
            >
              {t('common:button.login')}
            </Button>
            <Button
              className={classes.button}
              variant={'text'}
              color={'primary'}
              onClick={login}
            >
              {t('common:button.register')}
            </Button>
          </>
        )}
        <FormControl style={{paddingBottom: 8}}>
          <Select
            id="language-select"
            value={i18next.resolvedLanguage || 'cimode'}
            onChange={(e) => changeLanguage(e.target.value as string)}
          >
            {i18next.languages.map((lang) => {
              return (
                <MenuItem key={lang} value={lang}>{t(`common:language.${lang}`)}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Link
          href={configStore.appConfig.config?.imprintUrl || imprintUrl}
          target="_blank"
        >
          {t('menu.imprint')}
        </Link>
      </Box>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default observer(Sidebar);

const SearchIcon = () => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 53 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height={35}
    >
      <path
        d="M8.929 0A6.548 6.548 0 002.38 6.548v10.73a13.086 13.086 0 013.571-2.1v-8.63A2.977 2.977 0 018.93 3.57h34.523a2.977 2.977 0 012.977 2.977v25a2.977 2.977 0 01-2.977 2.976H23.75l3.571 3.571h16.131A6.548 6.548 0 0050 31.548v-25A6.547 6.547 0 0043.453 0H8.928z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M18.245 16.667a13.167 13.167 0 013.445 3.571h17a1.786 1.786 0 100-3.571H18.245zM33.928 27.381H23.81c0-1.238-.171-2.436-.493-3.571h10.612a1.786 1.786 0 010 3.571zM13.69 9.524a1.786 1.786 0 000 3.571h15.477a1.786 1.786 0 100-3.571H13.69zM10.714 38.095c2.225.004 4.396-.689 6.207-1.98l6.222 6.218a1.786 1.786 0 102.524-2.523l-6.22-6.222a10.714 10.714 0 10-8.733 4.507zm0-3.571a7.143 7.143 0 110-14.285 7.143 7.143 0 010 14.285z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

// color fill, stroke , widht 30
const WalletIcon = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 36 36"
      width="30"
      height={35}
    >
      <path
        d="M32 15h-1V9a1 1 0 00-1-1H6a1 1 0 01-1-.82v-.36A1 1 0 016 6h23.58a1 1 0 000-2H6a3 3 0 00-3 3 3.08 3.08 0 000 .36v20.57A4.1 4.1 0 007.13 32H30a1 1 0 001-1v-6h1a1 1 0 001-1v-8a1 1 0 00-1-1zm-3 15H7.13A2.11 2.11 0 015 27.93V9.88A3.11 3.11 0 006 10h23v5h-7a5 5 0 000 10h7zm2-7h-9a3 3 0 010-6h9z"
        fill={theme.palette.primary.main}
      />
      <circle
        className="prefix__clr-i-outline prefix__clr-i-outline-path-2"
        cx={23.01}
        cy={20}
        r={1.5}
        stroke={theme.palette.primary.main}
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

const FolderIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width={30}
      height={35}
      viewBox="0 0 50 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.114 10.796v-.014a7.184 7.184 0 00-7.184-7.184H18.588L14.601.412a1.796 1.796 0 00-1.12-.391H7.184A7.184 7.184 0 000 7.205v21.551a7.184 7.184 0 007.184 7.184H40.41a5.388 5.388 0 005.172-3.88l4.203-14.367a5.388 5.388 0 00-5.173-6.897h-1.498zM7.184 3.614h5.668l3.987 3.186c.317.254.71.393 1.117.395H35.93a3.592 3.592 0 013.592 3.592v.01H11.674a5.388 5.388 0 00-5.173 3.88L3.592 24.65V7.205a3.592 3.592 0 013.592-3.592zM9.95 15.682a1.796 1.796 0 011.724-1.294h32.938a1.796 1.796 0 011.724 2.3l-4.202 14.367a1.796 1.796 0 01-1.724 1.293H7.482a1.796 1.796 0 01-1.724-2.299L9.95 15.682z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
const AddIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
