import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../common/stores/store';
import { ITheme } from '../../common/theme';
import InfoBox from '../InfoBox';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    wrapper: theme.mixins.fullstopWrapper,
  })
);

const ConfirmEmail = () => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const { commonStore, userStore } = useStore();

  const checkEmailConfirmation = () => {
    commonStore.setAppLoading(true);
    setTimeout(async () => {
      await userStore.removeUser();
      userStore.login();
    }, 1000);
  };

  return (
    <div className={classes.wrapper}>
      <InfoBox
        title={t('error.email_not_confirmed.title')}
        subtitle={t('error.email_not_confirmed.subtitle')}
        leftButton={{
          variant: 'contained',
          onClick: checkEmailConfirmation,
          text: t('error.email_not_confirmed.check_again'),
        }}
        rightButton={{
          variant: 'outlined',
          onClick: userStore.sendEmailConfirmation,
          text: t('error.email_not_confirmed.resend'),
        }}
      />
    </div>
  );
};

export default ConfirmEmail;
