import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from '../../common/theme';
import Logo from '../Logo';
import { drawerWidth } from '../Sidebar';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      marginRight: 'auto',
    },
    topbar: {
      ...theme.mixins.toolbar, // necessary for content to be below top bar
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

interface IProps {
  handleDrawerToggle: () => void;
}
const Topbar = ({ handleDrawerToggle }: IProps) => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar} color="default">
      <Toolbar className={classes.topbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Logo inSidebar={false} />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
