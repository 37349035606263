import {CircularProgress,Button, Card, CardContent, createStyles, TextField, makeStyles, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../common/stores/store';
import { ITheme } from '../../common/theme';
import {useEffect, useRef, useState} from "react";
import api from "../../common/api";
import clsx from "clsx";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    page: theme.mixins.fullstopWrapper,
    wrapper: { ...theme.mixins.wrapper },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
      justifyContent: 'center',
    },
    infoIcon: {
      marginRight: theme.spacing(1),
    },
    root: {
      display: 'flex',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      maxWidth: '640px',
    },
    input: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cardContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
    cardSubtitle: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      marginBottom: theme.spacing(4),
    },
    authButtonsContainer: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
      alignItems: 'center',
    },
    leftButton: {
      ...theme.mixins.defaultButton,
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
    leftMargin: {
      marginLeft: theme.spacing(1),
    },
    rightMargin: {
      marginRight: theme.spacing(1),
    },
    rightButton: {
      ...theme.mixins.defaultButton,
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
    buttonText: {
      display: "flex",
      alignItems: 'center',
      wordBreak: 'break-word'
    },
    spinner: {
      color: theme.palette.common.white,
      marginRight: '8px',
      marginBottom: '4px',
      flexShrink: 0,
    }
  })
);

const getReferralErrorText = (msg: string) => {
  if (msg.includes('in use')) return 'user_signup.error.alreadyInUse';
  if (msg.includes('expired')) return 'user_signup.error.alreadyExpired';
  if (msg.includes('not found')) return 'user_signup.error.notFound';
  return msg;
};

const UserSignup = () => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const [refRequired, setRefRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState('');
  const [errorText, setErrorText] = useState('');
  const { commonStore, userStore } = useStore();
  const failedCode = useRef<string | null>(null);

  useEffect(() => {
    api.Wlm.getRegisterTemplate().then((res: any) => {
      setRefRequired(Boolean(res.referralCode?.isMandatory))
    });
  }, [])

  const check = async () => {
    commonStore.setAppLoading(true);
    await userStore.checkUser();
    commonStore.setAppLoading(false);
  };
  const signUp = async () => {
    try {
      setLoading(true);
      await api.Wlm.signup({referralCode: referral, isDataPolicyAccepted: true});
      check();
    } catch (error) {
      commonStore.setAppLoading(false);
      setLoading(false);
      failedCode.current = referral;
      setErrorText(getReferralErrorText(error?.response?.data?.error?.message) || error.message || '');
    }
  }

  const error = failedCode.current === referral ? errorText : '';

  return (
    <div className={classes.page}>
      <div className={classes.wrapper}>
        <Card className={classes.root} raised>
          <div className={classes.cardContainer}>
            <CardContent>
              <div className={classes.cardHeader}>
                <Typography component="h5" align="center" variant="h5">
                  {t('user_signup.title')}
                </Typography>
              </div>
              <Typography variant="subtitle1" className={classes.cardSubtitle}>
                {t(refRequired ? 'user_signup.referral_code.disclaimerRequired' : 'user_signup.referral_code.disclaimerOptional')}
              </Typography>
              <div className={classes.input}>
                <TextField
                  label={t('user_signup.referral_code.label')}
                  variant="outlined"
                  onChange={(e) => setReferral(e.target.value)}
                  value={referral}
                  error={Boolean(error)}
                  helperText={t(error)}
                  placeholder="HHHHHHHHCFA62BC"
                  required={refRequired}
                />
              </div>
            </CardContent>

            <CardContent className={classes.authButtonsContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={signUp}
                className={clsx(
                  classes.rightButton,
                )}
                disabled={(refRequired && !referral) || loading}
              >
                <div className={clsx(classes.buttonText)}>{loading ? <CircularProgress className={clsx(classes.spinner)} color="secondary" size={16} /> : null}
                  {t('user_signup.button')}
                </div>
              </Button>
            </CardContent>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default UserSignup;
