import { useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../common/stores/store';
import { ITheme } from '../../common/theme';
import LoginRequired from '../../components/LoginRequired';
import InfoBox from '../../components/InfoBox';
import { walletsPath, recordsPath } from '../../common/constants/routes';
import NewRecordForm from './NewRecordForm';
import ErrorInfoBox from '../../components/ErrorInfoBox';
let clearTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: {
      ...theme.mixins.verticalAlign,
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    wrapper: {
      ...theme.mixins.wrapper,
      minHeight: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
    pendingRecordInfo: {
      marginTop: theme.spacing(2),
    },
  })
);

const NewRecord = () => {
  const { t } = useTranslation(['records', 'common']);
  const classes = useStyles();
  const { userStore, walletStore, navigationStore, recordStore } = useStore();
  const [redirectId, setRedirectId] = useState('');
  const [pendingRecordsLoaded, isPendingRecordsLoaded] = useState(false);

  useEffect(() => {
    if (recordStore.formState.selectedWalletIdx === 0) {
      recordStore.getFormState();
    }
  });

  useEffect(() => {
    if (userStore.loggedIn/* && recordStore.pendingRecord*/) {
      recordStore.getPendingRecord();
    }
  }, [recordStore, userStore.loggedIn]);

  useEffect(() => {
    if (recordStore.pendingRecord) {
      clearTimer = setTimeout(recordStore.getPendingRecord, 2000);
      return () => clearTimeout(clearTimer);
    }
  }, [recordStore, recordStore.pendingRecord]);

  useEffect(() => {
    if (!recordStore.pendingRecordLoading) {
      isPendingRecordsLoaded(true);
    }
  }, [recordStore.pendingRecordLoading]);

  useEffect(() => {
    if (!recordStore.pendingRecordLoading && !recordStore.pendingRecord) {
      if (redirectId) {
        navigationStore.push(`${recordsPath}/${redirectId}`);
      } else {
        walletStore.loadWalletsSorted();
      }
    }
  }, [
    walletStore,
    recordStore,
    recordStore.pendingRecordLoading,
    redirectId,
    navigationStore,
  ]);

  const openWalletsView = () => {
    navigationStore.push({
      pathname: walletsPath,
      state: {
        comingFromNewRecordForm: true,
      },
    });
  };

  if (!userStore.loggedIn) {
    return <LoginRequired />;
  }

  if (recordStore.error) {
    const { error } = recordStore;
    return <ErrorInfoBox title={error.title} subtitle={error.message} />;
  }

  const { recordsLoading, pendingRecord, pendingRecordLoading } = recordStore;

  return (
    <div className={classes.wrapper}>
      {walletStore.walletsLoading ||
      recordsLoading ||
      pendingRecord ||
      pendingRecordLoading ||
      !pendingRecordsLoaded ? (
        <div className={classes.verticalAlign} style={{ maxWidth: '500px' }}>
          <CircularProgress color={'primary'} />
          {pendingRecord ? (
            <>
              <Typography color="primary" className={classes.pendingRecordInfo}>
                {t('new_record.processing.info_paragraph_1', {
                  recordId: pendingRecord.id,
                })}
              </Typography>
              <Typography color="primary" className={classes.pendingRecordInfo}>
                {t('new_record.processing.state_text')}{' '}
                {t(`new_record.state.${pendingRecord?.state?.toLowerCase()}`)}
              </Typography>

              <Typography
                variant={'h6'}
                color="primary"
                className={classes.pendingRecordInfo}
              >
                {t('new_record.processing.info_paragraph_2')}
              </Typography>
              <Typography
                align="center"
                color="primary"
                className={classes.pendingRecordInfo}
              >
                {t('new_record.processing.info_paragraph_3')}
              </Typography>
            </>
          ) : null}
        </div>
      ) : walletStore.wallets.length ? (
        <NewRecordForm setRedirectId={setRedirectId} />
      ) : (
        <div className={classes.verticalAlign}>
          <InfoBox
            title={t('new_record.no_wallet.title')}
            subtitle={t('new_record.no_wallet.subtitle')}
            leftButton={{
              variant: 'contained',
              onClick: openWalletsView,
              text: t('common:button.add'),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default observer(NewRecord);
