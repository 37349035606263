import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  makeStyles,
  createStyles,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper, Typography, SvgIconProps, SvgIcon,
} from '@material-ui/core';
import {ITheme} from '../../common/theme';
import {useStore} from '../../common/stores/store';
import LoginRequired from '../../components/LoginRequired';
import InfoBox from '../../components/InfoBox';
import {
  certificatePath, recordsPath,
} from '../../common/constants/routes';
import ErrorInfoBox from '../../components/ErrorInfoBox';
import {DEFAULT_RECORDS_PAGE_SIZE} from '../../common/models/record';
import {useTranslation} from 'react-i18next';
import QRCode from "qrcode.react";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: {...theme.mixins.verticalAlign, height: '100%'},
    table: {
      minWidth: 480,
    },
    tableWrapper: {
      marginTop: theme.spacing(2),
    },
    wrapper: {
      ...theme.mixins.wrapper,
      minHeight: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      maxWidth: '90vw',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
    topSection: {
      ...theme.mixins.verticalAlign,
      width: 'max(50%, min(500px, 90vw))',
      alignSelf: 'flex-start',
      marginBottom: theme.spacing(2),
    },
    topSectionWButton: {
      display: 'flex',
      alignItems: 'flex-start',
      alignSelf: 'flex-start',
      width: '100%',
      flexWrap: 'wrap',
    },
    searchBarRoot: {
      flexGrow: 1,
      '& .MuiOutlinedInput-root': {
        '&:hover fieldSet': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        border: '2px solid',
        borderRadius: '6px',
      },
    },
    addRecordButton: {
      ...theme.mixins.defaultButton,
      fontWeight: theme.typography.fontWeightMedium,
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      minWidth: 'min(300px, 90vw)',
    },
    nameCell: {
      ...theme.mixins.overflowEllipsis,
      maxWidth: '180px',
      [theme.breakpoints.down('xs')]: {
        width: '100px',
        maxWidth: '100px',
      },
    },
    walletButtonLabel: {
      ...theme.mixins.overflowEllipsis,
      display: 'block',
    },
    creationDateRangeIndicator: {
      padding: `0 ${theme.spacing(2)}px`,
    }
  })
);

const shortenTableString = (s: string) =>
  s ? s.slice(0, 5) + '...' + s.slice(-3) : '-';


const ScanQRIcon = (props: SvgIconProps) => (
  <SvgIcon width={30} height={30} {...props}>
    <path d="M9.5 6.5v3h-3v-3h3M11 5H5v6h6V5zm-1.5 9.5v3h-3v-3h3M11 13H5v6h6v-6zm6.5-6.5v3h-3v-3h3M19 5h-6v6h6V5zm-6 8h1.5v1.5H13V13zm1.5 1.5H16V16h-1.5v-1.5zM16 13h1.5v1.5H16V13zm-3 3h1.5v1.5H13V16zm1.5 1.5H16V19h-1.5v-1.5zM16 16h1.5v1.5H16V16zm1.5-1.5H19V16h-1.5v-1.5zm0 3H19V19h-1.5v-1.5zM22 7h-2V4h-3V2h5v5zm0 15v-5h-2v3h-3v2h5zM2 22h5v-2H4v-3H2v5zM2 2v5h2V4h3V2H2z"></path>
  </SvgIcon>
);

const CertificateSearch = () => {
  const {t} = useTranslation(['records', 'bookmarks']);
  const classes = useStyles();
  const theme = useTheme();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    userStore,
    dialogStore,
    certificateStore: {
      foundCertificates,
      searchRecordsAdvanced,
      setFoundCertificates,
      advancedSearchOngoing,
      setSearchOngoing,
      error,
      setError,
      totalFoundCertificates,
    },
  } = useStore();

  const [page, setPage] = useState(0);
  const [searched, setSearched] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_RECORDS_PAGE_SIZE);

  useEffect(() => {
    if (!searched) {
      setFoundCertificates([]);
      setError(null);
    }
  }, [searched, setError, setFoundCertificates])

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    search(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    search(0, newRowsPerPage);
  };

  const [mainProperty, setMainProperty] = useState('');
  const [id, setId] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const search = (page = 0, rows?: number) => {
    setSearched(true);
    rows = rows || rowsPerPage;
    setSearchOngoing(true);
    setPage(page);
    searchRecordsAdvanced({limit: rows, skip: page * rows, mainProperty, dateTo, dateFrom, id});
  };

  const openCertificate = (id: string, formId: string) => {
    window.open(`${window.location.origin}${certificatePath}?id=${id}&form=${formId}`, '_blank');
  };

  const openRecordDetails = (id: string) => {
    window.open(`${window.location.origin}${recordsPath}/${id}`, '_blank');
  };


  const showQR = (id: string, formId: string) => {
    dialogStore.setDialogConfig({
      open: true,
      onClose: dialogStore.closeDialog,
      onCancel: dialogStore.closeDialog,
      onConfirm: dialogStore.closeDialog,
      title: t('certificate.table.shareQRTitle'),
      component: () => (
        <QRCode
          value={`${window.location.origin}${certificatePath}?id=${id}&form=${formId}`}
          includeMargin={true}
          size={256}
        />
      ),
      singleButtonAction: dialogStore.closeDialog,
      singleButtonText: t('common:button.close'),
    });

  }

  useEffect(() => {
    if (dateFrom && dateTo && new Date(dateFrom).getTime() > new Date(dateTo).getTime()) {
      setDateTo('');
    }
  }, [dateFrom, dateTo])

  if (!userStore.loggedIn) {
    return <LoginRequired/>;
  }

  if (error && searched) {
    return <ErrorInfoBox title={error.title} subtitle={error.message}/>;
  }

  return (
    <div className={classes.wrapper}>
      <>
        <div className={classes.topSection}>
          <TextField
            color="primary"
            classes={{root: classes.searchBarRoot}}
            label={t('certificate.search.mainPropertyLabel')}
            type="text"
            variant="outlined"
            size={'small'}
            value={mainProperty}
            InputLabelProps={{shrink: true}}
            onChange={(event) => setMainProperty(event.target.value)}
          />
        </div>
        <div className={classes.topSection}>
          <TextField
            color="primary"
            classes={{root: classes.searchBarRoot}}
            label={t('certificate.search.dateFromLabel')}
            type="date"
            variant="outlined"
            size={'small'}
            value={dateFrom}
            InputLabelProps={{shrink: true}}
            onChange={(event) => setDateFrom(event.target.value)}
          />
          <Typography className={classes.creationDateRangeIndicator}>&nbsp;–&nbsp;</Typography>
          <TextField
            color="primary"
            classes={{root: classes.searchBarRoot}}
            label={t('certificate.search.dateToLabel')}
            type="date"
            variant="outlined"
            size={'small'}
            value={dateTo}
            InputLabelProps={{shrink: true}}
            InputProps={{inputProps: { min: dateFrom} }}
            onChange={(event) => setDateTo(event.target.value)}
          />
        </div>
        <div className={classes.topSectionWButton}>
          <div className={classes.topSection}>
            <TextField
              color="primary"
              classes={{root: classes.searchBarRoot}}
              label={t('certificate.search.idLabel')}
              type="text"
              variant="outlined"
              size={'small'}
              value={id}
              InputLabelProps={{shrink: true}}
              onChange={(event) => setId(event.target.value)}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            disabled={Boolean(!mainProperty && !dateTo && !dateFrom && !id)}
            className={classes.addRecordButton}
            onClick={() => search()}
          >
            {t('certificate.search.button')}
          </Button>
        </div>


        {advancedSearchOngoing ? (
          <div className={classes.verticalAlign}><CircularProgress color="primary"/></div>
        ) : (
          <>
            {foundCertificates.length && searched ? <TableContainer className={classes.tableWrapper} component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('certificate.table.mainProperty')}</TableCell>
                    <TableCell align="center">
                      {t('record.id')}
                    </TableCell>
                    {matchesSmallScreen ? null : (
                      <TableCell align="center">
                        {t('certificate.table.date')}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {t('certificate.table.formId')}
                    </TableCell>
                    <TableCell align="center">
                      {t('certificate.table.share')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {foundCertificates.map((record) => (
                    <TableRow key={record.id}>
                      <TableCell
                        align="center"
                        style={{cursor: 'pointer'}}
                        onClick={() => openCertificate(record.id, record.formId)}
                      >
                        {matchesSmallScreen
                          ? shortenTableString(record.mainProperty)
                          : record.mainProperty}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{cursor: 'pointer'}}
                        className={classes.nameCell}
                        onClick={() => openRecordDetails(record.id)}
                      >
                        <span>{record.id}</span>
                      </TableCell>

                      {matchesSmallScreen ? null : (
                        <TableCell align="center">
                          {record.creationTime ? new Date(record.creationTime).toLocaleDateString() : '-'}
                        </TableCell>
                      )}
                      <TableCell align="center">
                        {record.formId}
                      </TableCell>
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => showQR(record.id, record.formId)}
                        align="center"
                      >
                        <ScanQRIcon />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> : null}
            {foundCertificates.length && searched ? <TablePagination
              style={{width: '100%'}}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalFoundCertificates}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> : null}
            {!foundCertificates.length && searched ? <div className={classes.verticalAlign}>
              <InfoBox
                title={t('certificate.error.no_certificates_found.title')}
                subtitle={t('certificate.error.no_certificates_found.subtitle')}
              />
            </div> : null}
          </>
        )}
      </>
    </div>
  );
};

export default observer(CertificateSearch);
