import env from '@beam-australia/react-env';
import { parseDomain, ParseResultType } from "parse-domain";
import axios from 'axios';
import { IAuthorization, IFetchAuthorization } from '../models/authorization';
import { IWebConfig } from '../models/discoveryConfig';
import {
  ICreateRecord,
  IRecord,
  DEFAULT_RECORDS_PAGE_SIZE,
  IRecordLink, ICertificateForm, ICreateCertificate, ICertificateRecord,
} from '../models/record';
import { IRepository, IRepositoryState } from '../models/repositories';
import { ISignedMessage } from '../models/signature';
import { IVersion } from '../models/version';
import { ICreateWallet, IWallet } from '../models/wallet';

import http, { IRequestBody, ITimestamp } from './http';
import { store } from "../stores/store";
import {IUser} from "../models/user";

const Authorizations = {
  fetch: (
    address: string,
    value: IFetchAuthorization,
    signedMessage: ISignedMessage
  ) =>
    http.post<IAuthorization>(`/authorizations/${address}`, {
      value,
      signedMessage,
    }),
};

const Bookmarks = {
  create: (id: string) => http.put(`/records/${id}/bookmarks`),
  delete: (id: string) => http.delete(`/records/${id}/bookmarks`),
};

const Wlm = {
  getUser: () => http.post<IUser>(`${store.commonStore.discovery?.endpoints.wlmApi || 'https://wlm-be-uat.dev.coreledger.net/api'}/auth/signin-oidc`, null),
  signup: (body: any) => http.post(`${store.commonStore.discovery?.endpoints.wlmApi || 'https://wlm-be-uat.dev.coreledger.net/api'}/auth/signup`, body),
  getRegisterTemplate: () => http.get<{ referralCode: { isMandatory: boolean } }>(`${store.commonStore.discovery?.endpoints.wlmApi || 'https://wlm-be-uat.dev.coreledger.net/api'}/registrationtemplates/active`),
};

const Records = {
  details: (id: string) => http.get<IRecord>(`/records/${id}`),
  generatePok: (address?: string) => http.post<{id: string}>(`/pok`, address ? { address } : undefined),
  getPokByAddress: (address: string) => http.get<boolean>(`/pok/${address}`),
  getTokens: (id: string, address: string) => http.get<{sparks: string}>(`/tokens/${address}/${id}`),
  detailsForCertificate: (id: string) => http.get<IRecord>(`/records/${id}`),
  formById: (id: string) => http.get<ICertificateForm>(`/forms/${id}`),
  myForms: () => http.get<ICertificateForm[]>(`/forms/my/active`),
  list: (limit = DEFAULT_RECORDS_PAGE_SIZE, skip = 0, search?: string) =>
    http.get<IRecord[]>(
      `/records?limit=${limit}&skip=${skip}${search ? `&search=${search}` : ''}`
    ),
  certificates: (limit = DEFAULT_RECORDS_PAGE_SIZE, skip = 0, search?: string) =>
    http.get<ICertificateRecord[]>(
      `/records/certificates/my?limit=${limit}&skip=${skip}${search ? `&search=${search}` : ''}`
    ),
  certificatesSearch: ({
    limit = DEFAULT_RECORDS_PAGE_SIZE,
    skip = 0,
    search,
    mainProperty,
    dateFrom,
    dateTo,
    id,
  }: {
    skip?: number;
    limit?: number;
    search?: string;
    mainProperty?: string;
    dateFrom?: string;
    dateTo?: string;
    id?: string;
  }) =>
    http.get<ICertificateRecord[]>(
      `/records/certificates/my?limit=${limit}&skip=${skip}${search ? `&search=${search}` : ''}${mainProperty ? `&mainProperty=${mainProperty}` : ''}${dateFrom ? `&startDate='${dateFrom}'` : ''}${dateTo ? `&endDate='${dateTo}'` : ''}${id ? `&id=${id}` : ''}`
    ),
  create: (record: ICreateRecord | FormData) =>
    http.post<IRecord>('/records', record),
  createCertificate: (record: ICreateCertificate | FormData) =>
    http.post<ICertificateRecord>('/records/certificates', record),
  getPending: () => http.get<IRecord[]>(`/records?category=pending`),
  getAssetLinks: (id: string) =>
    http.get<IRecordLink[]>(`/records/${id}/links`),
};

const Repositories = {
  get: (address: string) =>
    http.get<IRepositoryState>(`/repositories/${address}`),
  upload: (address: string, recordId: string, data: FormData) =>
    http.put<IRepository>(`/repositories/${address}/${recordId}`, data),
  download: (
    address: string,
    recordId: string,
    data: IRequestBody<ITimestamp>
  ) =>
    axios.post<Blob>(`/repositories/${address}/${recordId}`, data, {
      responseType: 'blob',
    }),
};

const Wallets = {
  list: () => http.get<IWallet[]>('/wallets'),
  create: () => http.post<ICreateWallet>('/wallets'),
  delete: (id: string) => http.delete(`/wallets/${id}`),
};

const parsedDomain = parseDomain(window.location.hostname);

const clientId = parsedDomain.type === ParseResultType.Listed ? `${parsedDomain.domain}.${parsedDomain.topLevelDomains.join('.')}` : window.location.hostname;

const WebConfig = {
  getDiscoveryServiceSettings: () =>
    http.get<IWebConfig>(
      `/discovery/${env('DISCOVERY_CONFIG_ID') || clientId}`
    ),
  getVersion: () => http.get<IVersion>('/version'),
};

const User = {
  sendConfirmationEmail: () => http.post('/users/email/confirmation'),
};

const api = {
  Authorizations,
  Bookmarks,
  Records,
  Repositories,
  User,
  Wallets,
  WebConfig,
  Wlm,
};

export default api;
