import { createBrowserHistory, LocationDescriptor } from 'history';
import { action, makeObservable } from 'mobx';

export default class NavigationStore {
  history = createBrowserHistory();

  constructor() {
    makeObservable(this, {
      push: action,
      replace: action,
      go: action,
    });
  }

  push = (location: LocationDescriptor) => {
    this.history.push(location);
  };

  replace = (location: LocationDescriptor) => {
    this.history.replace(location);
  };

  go = (n: number) => {
    this.history.go(n);
  };
}
