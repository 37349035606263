import { action, makeObservable, observable } from 'mobx';
import { Wallet } from 'ethers';
import { IError } from '../models/error';
import errorNotify from '../utils/notifications/errorNotify';
import storage, { storageKeys } from '../utils/storage';
import i18next from 'i18next';

export default class LocalWalletStore {
  wallet: Wallet | undefined = undefined;
  walletLoading = true;
  error: IError | null = null;

  constructor() {
    makeObservable(this, {
      wallet: observable,
      walletLoading: observable,
      error: observable,
      setError: action,
      setWallet: action,
      setWalletLoading: action,
    });
  }

  setError = (error: IError | null) => {
    this.error = error;
  };

  setWallet = (wallet: Wallet | undefined) => {
    this.wallet = wallet;
  };

  setWalletLoading = (walletLoading: boolean) => {
    this.walletLoading = walletLoading;
  };

  createNewWallet = async () => {
    this.setWalletLoading(true);
    try {
      const wallet = Wallet.createRandom();
      storage.setItem(storageKeys.LOCAL_WALLET, wallet.mnemonic.phrase);
      this.setWallet(wallet);
    } catch (error) {
      errorNotify(i18next.t('error.local_wallet.create_failed'));
    } finally {
      this.setWalletLoading(false);
    }
  };

  loadWalletFromSeed = async () => {
    this.setWalletLoading(true);
    const storedWallet = storage.getItem(storageKeys.LOCAL_WALLET);
    try {
      if (storedWallet) {
        const wallet = Wallet.fromMnemonic(storedWallet);
        this.setWallet(wallet);
      } else {
        errorNotify(i18next.t('error.local_wallet.load_failed'));
      }
    } catch (err) {
      console.error(err);
      errorNotify(i18next.t('error.local_wallet.load_failed'));
    } finally {
      this.setWalletLoading(false);
    }
  };

  initLocalWallet = async () => {
    this.setWalletLoading(true);
    const storedWallet = storage.getItem(storageKeys.LOCAL_WALLET);
    try {
      if (storedWallet) {
        const wallet = Wallet.fromMnemonic(storedWallet);
        this.setWallet(wallet);
      } else {
        const wallet = Wallet.createRandom();
        storage.setItem(storageKeys.LOCAL_WALLET, wallet.mnemonic.phrase);
        this.setWallet(wallet);
      }
    } catch (err) {
      console.error(err);
      errorNotify(i18next.t('error.local_wallet.load_failed'));
    } finally {
      this.setWalletLoading(false);
    }
  };

  removeLocalWallet = () => {
    this.setWallet(undefined);
    storage.removeItem(storageKeys.LOCAL_WALLET);
  };
}
