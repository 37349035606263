import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from '@material-ui/core';
import { imprintUrl } from '../../common/constants/routes';
import { useStore } from '../../common/stores/store';
import { useTranslation } from 'react-i18next';

const CookieDialog = () => {
  const { t } = useTranslation(['cookies']);
  const [expand, setExpand] = useState(false);
  const { configStore } = useStore();
  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      <p>{t('dialog_paragraph_1')}</p>

      <p>{t('dialog_paragraph_2')}</p>

      <p>{t('service_provided_by')}</p>
      {!expand && (
        <Button color="primary" variant="text" onClick={() => setExpand(true)}>
          {t('common:button.expand')}
        </Button>
      )}

      {expand && (
        <div>
          <h3>{t('detailed_explanation.title')}</h3>
          <p>{t('detailed_explanation.paragraph_1')}</p>

          <p>
            {t('detailed_explanation.paragraph_2')}{' '}
            <Link
              href={configStore.appConfig.config?.imprintUrl || imprintUrl}
              target="_blank"
            >
              {t('detailed_explanation.policies')}
            </Link>
            {t('detailed_explanation.paragraph_2')}
          </p>

          <p>
            {t('detailed_explanation.service_provided_by.pretext')}
            <br />
            {t('detailed_explanation.service_provided_by.company_name')}
            <br />
            {t('detailed_explanation.service_provided_by.company_address')}
            <br />
            {t('detailed_explanation.service_provided_by.company_postcode')}
            <br />
            {t('detailed_explanation.service_provided_by.company_country')}
          </p>
        </div>
      )}
    </div>
  );
};

export default CookieDialog;
