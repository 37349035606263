/**
 * File metadata information
 */
export interface IFileMetadata {
  /**
   * Filename. Must be the original uploaded name.
   * @example "Testfile.jpg"
   */
  name: string;

  /**
   * Filetype. Generally in form of *\/*
   * @example "image/jpeg"
   */
  type: string;

  /**
   * Method used to hash the file. Notrzr always uses SHA3-512
   * @example "SHA3-512"
   */
  method: string;

  /**
   * Filesize. Amount of bytes.
   * @example "55042"
   */
  size: string;

  /**
   * Filehash. Must be the hash of the original unecrypted file.
   * Hash is done with SHA3-256.
   * @example "91b96b11e443d6482c6a73893bb01171f9b6714b3838db3d3d69f72fcb6214ea"
   */
  hash: string;

  /**
   * URI to a copy of the file.
   * @example "https://mycloudstorage.com/Testfile.jpg"
   */
  uri?: string;
}

export enum HashMethod {
  SHA2_256 = 'SHA2-256',
  SHA3_256 = 'SHA3-256',
  SHA3_512 = 'SHA3-512',
}
