import svgErrorNotFound from './svgs/error-1-_3_.svg'
import svgErrorData from './svgs/error-3.svg'
import svgErrorForm from './svgs/error-4.svg'
import svgErrorIssuer from './svgs/error-2-_1_.svg'
import cert1 from './svgs/cert-0x-_1_ (1).svg'
import cert4 from './svgs/cert-green2.svg'
import cert5 from './svgs/cert-long-font-x2.svg'
import cert0 from './svgs/cert-0x-2.svg'
import cert2 from './svgs/cert-0x3.svg'
import certF from './svgs/cert-of-education-1.svg'
import certA from './svgs/cert-aroma.svg'
import { ReactSVG } from "react-svg";
import {createStyles, makeStyles, SvgIcon, Typography, useTheme} from "@material-ui/core";
// @ts-ignore
// import wrapSvgText from 'wrap-svg-text';
import {NavLink, useLocation} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import api from "../../common/api";
import {ICertificateForm, IRecord} from "../../common/models/record";
import LoadingPage from "../../components/LoadingPage";
import qrcode from "qrcode";
import {certificatePath, recordsPath} from "../../common/constants/routes";
import theme from "../../common/theme";
import {useBbox} from "./utils";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    wrapperVertical: {
      justifyContent: 'flex-start',
    },
    absolute: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 99999,
    },
    wrapperMain: {
      flex: 1,
    },
    verification: {
      flex: 0,
      width: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: theme.spacing(2),
    },
    verificationDivider: {
      flex: 0,
      borderTopWidth: 4,
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.divider,
      marginTop: theme.spacing(2),
    },
    verificationInfoBlock: {
      display: 'flex',
      minWidth: '34%',
      whiteSpace: 'nowrap',
      alignItems: 'center',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    }
  })
);

const Verified = () => {
  const theme = useTheme();

  return <SvgIcon width="16" height="16" viewBox="0 0 24 24">
    <path fill={theme.palette.primary.main} d="M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"></path>
  </SvgIcon>
}

function useQuery<T extends Record<string, string | null>>() {
  const { search } = useLocation();

  return useMemo(() => new Proxy(new URLSearchParams(search), { get: (t, p, s) => {
    if (typeof p === 'string') {
      return t.get(p);
    }
    return Reflect.get(t,p,s);
    } }) as any as Record<string, string | null>, [search]) as T;
}

type ExtendedRecord = IRecord & {variables?: Record<string, string>} & { parsedData: Record<string, string> };
type ExtendedForm = ICertificateForm & { constants: Record<string, string> };

const greenCert = ['0x9e1f0d7c8b29'];
const green2Cert = ['0x972c9a8767b3', '0x13c2af84d5e9'];
const brownCert = ['0x4b3e2a1c0857', '0x6a7b9d57c151', '0x7f856d9e2b10'];
const purpleCert = ['0x6d8f9a4cbe17', '0x1f13ac87f0e0', '0x29b741e6f8c5'];
const longCert = ['0xb3a715835874', '0xaf3745387458', '0x8d3f70bce296'];
const educationCert = ['0x6a5fc830d1ef', '0x4ae9c2363be5'];
const educationCert2 = educationCert; // fake

export const getCertById = (formId?: string) => {
  // return artCert;
  if (purpleCert.includes(formId?.toLowerCase() || '')) return cert0;
  if (green2Cert.includes(formId?.toLowerCase() || '')) return cert4;
  if (educationCert.includes(formId?.toLowerCase() || '')) return certF;
  if (educationCert2.includes(formId?.toLowerCase() || '')) return certA;
  if (longCert.includes(formId?.toLowerCase() || '')) return cert5;
  if (greenCert.includes(formId?.toLowerCase() || '')) return cert1;
  if (brownCert.includes(formId?.toLowerCase() || '')) return cert2;
}

const getCertSvg = (formId: string, record?: ExtendedRecord | null, form?: ExtendedForm | null) => {
  // return {error: null, src: certTS};
  if (record && form?.issuer && record?.author !== form?.issuer) return {error: 'issuer', src: svgErrorIssuer};
  if (!record) return {error: 'not found', src: svgErrorNotFound};
  if (!form) return {error: 'data', src:svgErrorData};
  const allMandatoryFields = form.mandatory.reduce((accum, field) => {
    return [...accum, field.id];
  }, [] as string[]);
  if (!record.parsedData) return {error: 'form', src: svgErrorForm};
  if (allMandatoryFields.some(key => ![...Object.keys(record.parsedData), ...(Object.keys(form.constants) || [])].includes(key))) return {error: 'data', src: svgErrorForm};
  const certById = getCertById(formId) || form.certUrl;
  if (certById) return {error: null, src: certById};
  return {error: 'data', src: svgErrorForm};
}

const getCertData = (record: IRecord | null) => {
  try {
    const data = JSON.parse(record?.data || '');
    if (typeof data === "object") return data;
  } catch {
    return null;
  }
}

export const Certificates = () => {
  const theme = useTheme();
  const {t} = useTranslation(['records', 'common']);
  const params = useQuery<{ form: string; id: string; }>();
  const classes = useStyles();
  const [rects, updateRef] = useBbox();
  const [record, setRecord] = useState<ExtendedRecord | null>(null);
  const [form, setForm] = useState<ExtendedForm | null>(null);
  const [, setShowLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [rendering, setRendering] = useState(true);

  const getData = useCallback(async () => {
    setShowLoading(true);
    setInitialLoading(true);
    try {
      if (params.id) {
        const {value, variables} = await api.Records.detailsForCertificate(params.id);
        const data = getCertData(value);
        setRecord({...value, parsedData: {...variables, ...data}, variables});
        const form = await api.Records.formById(params.form);
        const formConstants = (form as any as ICertificateForm)?.constants.reduce((accum, constant) => {
          return ({...accum, ...constant })
        }, {} as Record<string, string>);
        setForm(({...form, constants: formConstants}) as any);
      }
    } catch {
      setShowLoading(false);
    }
    setShowLoading(false);
    setInitialLoading(false);
  }, [params.form, params.id])

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {src: svgSrc, error: svgError} = useMemo(() => {
    return getCertSvg(params.form, record, form);
  }, [params.form, record, form]);

  const recordData = useMemo(() => {
    return {...form?.constants, ...record?.parsedData}
  }, [form?.constants, record?.parsedData]);

  const [fakeInjected, setFakeInjected] = useState(false);
  const [showReal, setShowReal] = useState(false);

  useEffect(() => {
    if (fakeInjected) {
      const wait = async () => {
        await window.document.fonts.ready;
        await new Promise(resolve => setTimeout(resolve, 1000));
        setShowReal(true);
      }
      wait();
    }
  }, [fakeInjected]);

  const issuerDisplay = record?.author ? `0x${record.author.replace('0x', '').slice(0, 8)}...${record.author.slice(record.author.length - 8)}` : '';

  const showVerification = (!form?.verification || form.verification === 'visible') && showReal && !svgError && rects.width && !rendering;
  const verificationStyle = showVerification ? {width: window.innerWidth * 0.5 > rects.width ? rects.width + window.innerWidth * 0.04 : rects.width} : {};

  useEffect(() => {
    if (!showReal) return;
    const element = document.querySelector(`#certificate-${record?.id} svg`);
    if (element) {
      updateRef(`#certificate-${record?.id} svg`);
    }
    // eslint-disable-next-line
  }, [showReal]);

  const onInject = useCallback(async (svg: SVGSVGElement) => {
    const scale = /*(window.innerHeight / svgHeight) ||*/ 1;
    let textAnchorMainI = 1;
    const anchors = [];
    let textAnchorMain = svg.getElementById(`textAnchor-${textAnchorMainI}-block-1`) as SVGRectElement;
    while (textAnchorMain) {
      textAnchorMainI++;
      anchors.push(textAnchorMain);
      textAnchorMain = svg.getElementById(`textAnchor-${textAnchorMainI}-block-1`) as SVGRectElement;
    }
    await anchors.reduce(async (accum, anchor, i) => {
      await accum;
      let anchorI = 1;
      let allText = '';
      let maxLength = 0;
      let maxWidth = 0;
      let prevY = 0;
      let lastBlockHeight = 0;
      let height = 0;
      const elements = [];
      let textAnchor = svg.getElementById(`textAnchor-${i + 1}-block-${anchorI}`) as SVGRectElement;
      const sizeAnchor = svg.getElementById(`sizeAnchor-${i + 1}`) as SVGRectElement;
      const {width: blockWidth} = (sizeAnchor || (anchor.parentElement?.parentElement as SVGRectElement | null) || anchor)?.getBBox();
      while (textAnchor) {
        elements.push(textAnchor);
        anchorI++;
        const {height: blockHeight1} = ((textAnchor.parentElement as SVGRectElement | null) || textAnchor).getBBox();
        const {top, height: blockHeight2, width} = textAnchor.getBBox();
        if (!height && prevY) {
          height = Math.round((top - prevY));
        }
        prevY = top;
        if (sizeAnchor) {
          const {height: blockHeight} = sizeAnchor.getBBox();
          lastBlockHeight = +blockHeight > 4 ? blockHeight * 1.2 : Math.min(blockHeight1, blockHeight2) * 1.2;
        } else {
          lastBlockHeight = Math.min(blockHeight1, blockHeight2) * 1.2;
        }

        const text = textAnchor.textContent;
        allText = `${allText} ${text}`.replace(/\s+/g, ' ').trim();
        if ((text?.length || 0) > maxLength) {
          maxLength = text?.length || 0;
        }
        if ((maxWidth) < width) {
          maxWidth = width || 0;
        }
        textAnchor = svg.getElementById(`textAnchor-${i + 1}-block-${anchorI}`) as SVGRectElement;
      }
      const tags = allText.match(/<notrzr:[^>]+>/)?.[0]?.split(':').map(v => v.replace(/(<|>)/g, ''))
      let shouldCenter = false;
      let shouldJustify = false;
      if (tags) {
        allText = allText.replace(/<notrzr:[^>]+>/, '');
        if (tags.includes('center')) {
          shouldCenter = true;
        }
        if (tags.includes('justify')) {
          shouldJustify = true;
        }
      }
      Object.keys(recordData).forEach(key => {
        allText = allText.replace(`<${key}>`, recordData[key]);
      });
      const optionals = Array.from(allText.matchAll(/\{(?<ids>\*[^*]+\*)(?<content>[^}]+)}/g) || []);
      optionals.forEach((optional) => {
        const hasOptionals = optional.groups?.ids?.split(',')?.every(id => recordData[id.replace(/\*/g, '')]);
        if (hasOptionals) {
          allText = allText.replace(optional[0], optional.groups?.content || '');
        } else {
          allText = allText.replace(optional[0], '');
        }
      });
      height |= lastBlockHeight;
      let hadBoldStart = false;
      if (height && maxLength && anchor) {
        const words = allText.split(' ');
        let hadTestBoldStarts = 0;
        const {rows} = await words.reduce(async (accumPromise, word, wordIndex) => {
          const accum = await accumPromise;
          const lastI = accum.rows.length - 1 || 0;
          const lastElem = accum.rows[lastI] || '';
          hadBoldStart = hadBoldStart || /<notrzr-b-start>/.test(word);
          const possibleNextItem = `${lastElem} ${!hadBoldStart ? word : `<tspan font-weight="bold">${word.replace(/<notrzr-b-(start|end)>/g, '')}</tspan>`}`.trim();
          const fromPrevBold = hadTestBoldStarts;
          let localBolds = 0;
          if (possibleNextItem.match('<n-bs>')) {
            localBolds += possibleNextItem.match('<n-bs>')?.length ?? 0;
          }
          if (possibleNextItem.match('<n-be>')) {
            localBolds -= possibleNextItem.match('<n-be>')?.length ?? 0;
          }
          // const possibleLength = possibleNextItem.length;
          const resizeClone = anchor.cloneNode(false);
          // @ts-ignore
          resizeClone.setAttribute('id', `temp-size-calc`);
          // @ts-ignore
          resizeClone.setAttribute('fill', `transparent`);
          const getTextWithoutFakeTags = (v: string) => {
            return `${new Array(fromPrevBold).fill('<tspan font-weight="bold">').join('')}${`${v}`
              .replace(/<n-u[es]>/g, '')
              .replace(/<n-bs>/g, '<tspan font-weight="bold">')
              .replace(/<n-be>/g, '</tspan>')
              .replace(/<notrzr-spaces-(\d+)>/g, (_v, n) => '&nbsp;'.repeat(+n))}
                    ${new Array(Math.max(localBolds, 0)).fill('</tspan>').join('')}`
          }
          const nextTextWithoutFakeTags = getTextWithoutFakeTags(possibleNextItem);
          // resizeClone.textContent = possibleNextItem.replace(/<notrzr-b-(start|end)>/g, '');
          // @ts-ignore
          resizeClone.innerHTML = nextTextWithoutFakeTags;
          const justifyClone = resizeClone.cloneNode(false);
          // @ts-ignore
          justifyClone.setAttribute('id', `temp-justify-size-calc`);
          // @ts-ignore
          justifyClone.setAttribute('fill', `transparent`);
          const wrapJustify = (ws: string[]) => {
            // @ts-ignore
            justifyClone.innerHTML = ws.join('');
            const newParent = anchor.parentElement?.cloneNode();
            // @ts-ignore
            newParent.setAttribute('id', `temp-justify-size-calc-parent`);
            const tempChild = newParent?.appendChild(justifyClone) as SVGRectElement;

            const tempParent = anchor?.parentElement?.parentElement?.appendChild(newParent || tempChild) as SVGRectElement;
            const {width: tempWidth} = ((tempParent as any as SVGRectElement)).getBBox();
            // tempParent?.remove();
            if (ws.length > 1) {
              const spaceBetween = (blockWidth - tempWidth) / (ws.length - 1);
              return ws.map((w, wI) => wI ? `<tspan dx="${spaceBetween}">${w}</tspan>` : w).join('');
            }
            return ws.join(' ');
          }
          const newParent = anchor.parentElement?.cloneNode();
          // @ts-ignore
          newParent.setAttribute('id', `temp-size-calc-parent`);
          const tempChild = newParent?.appendChild(resizeClone) as SVGRectElement;

          const tempParent = (anchor?.parentElement?.parentElement || anchor?.parentElement || anchor).appendChild(newParent || tempChild) as SVGRectElement;
          await new Promise((r) => requestAnimationFrame(r));
          const {width: tempWidth} = ((tempParent as any as SVGRectElement)).getBBox();

          tempParent?.remove();

          const lastWordBold = hadBoldStart;
          hadBoldStart = hadBoldStart && !/<notrzr-b-end>/.test(word);
          const nextElem = !lastWordBold ? word : `<tspan font-weight="bold">${word.replace(/<notrzr-b-(start|end)>/g, '')}</tspan>`;                if (tempWidth <= blockWidth) {
            if (wordIndex + 1 === words.length && shouldJustify) {
              return {rows: [...accum.rows.slice(0, -1), possibleNextItem], words: [...accum.words, nextElem]};
            }
            return {rows: [...accum.rows.slice(0, -1), possibleNextItem], words: [...accum.words, nextElem]};
          } else {
            const finalizedRow = shouldJustify ? wrapJustify(accum.words) : lastElem;
            if (possibleNextItem.match('<n-bs>')) {
              hadTestBoldStarts = finalizedRow.match('<n-bs>')?.length ?? 0;
            }
            if (possibleNextItem.match('<n-be>')) {
              hadTestBoldStarts = Math.max(hadTestBoldStarts - (finalizedRow.match('<n-be>')?.length ?? 0), 0);
            }

            return {rows: [...accum.rows.slice(0, -1), finalizedRow, nextElem], words: [nextElem]};
          }
        }, Promise.resolve({rows: [], words: []} as { rows: string[], words: string[] }));

        let hadUnderlineStarts = 0;
        let hadBoldStarts = 0;

        await rows.reduce(async (accum, row, innerI) => {
          await accum;
          const clone = anchor.cloneNode(true);
          const ogY = anchor.getAttribute('y');
          // @ts-ignore
          clone.setAttribute('id', `updated-textAnchor-${i}-${innerI}`);
          // @ts-ignore
          clone.setAttribute('y', `${+(ogY || 0) + (innerI * lastBlockHeight) / scale}`);
          // clone.textContent = row;
          const fromPrevUnderline = hadUnderlineStarts;
          const fromPrevBold = hadBoldStarts;
          const uSMatch = row.match(/<n-us>/g);
          if (uSMatch) {
            hadUnderlineStarts += uSMatch?.length ?? 0;
          }
          const uEMatch = row.match(/<n-ue>/g);
          if (uEMatch) {
            hadUnderlineStarts -= uEMatch?.length ?? 0;
          }
          const bSMatch = row.match(/<n-bs>/g);
          if (bSMatch) {
            hadBoldStarts += bSMatch?.length ?? 0;
          }
          const bEMatch = row.match(/<n-be>/g);
          if (bEMatch) {
            hadBoldStarts -= bEMatch?.length ?? 0;
          }
          // @ts-ignore
          clone.innerHTML = `${new Array(fromPrevUnderline).fill('<tspan text-decoration="underline">').join('')}${new Array(fromPrevBold).fill('<tspan font-weight="bold">').join('')}${row
            .replace(/<n-us>/g, '<tspan text-decoration="underline">')
            .replace(/<n-ue>/g, '</tspan>')
            .replace(/<notrzr-b-start>/g, '<tspan font-weight="bold">')
            .replace(/<notrzr-b-end>/g, '</tspan>')
            .replace(/<n-bs>/g, '<tspan font-weight="bold">')
            .replace(/<n-be>/g, '</tspan>')
            .replace(/<notrzr-spaces-(\d+)>/g, (_v, n) => '&nbsp;'.repeat(+n))
          }${new Array(hadBoldStarts).fill('</tspan>').join('')}${new Array(hadUnderlineStarts).fill('</tspan>').join('')}`;
          // @ts-ignore
          // if (shouldCenter) clone.setAttribute('opacity', `0`);
          if (anchor.parentElement?.parentElement) {
            const newParent = anchor.parentElement.cloneNode();
            newParent.appendChild(clone) as SVGRectElement;
            // @ts-ignore
            newParent.setAttribute('id', `updated-textAnchorParent-${i}-${innerI}`);
            anchor.parentElement.parentElement.appendChild(newParent);
          } else {
            anchor.parentElement?.appendChild(clone) as SVGRectElement;
          }
          if (shouldCenter || (shouldJustify && innerI === rows.length - 1)) {
            /*await */
            new Promise((r) => requestAnimationFrame(() => {
              const mountedNode = svg.getElementById(`updated-textAnchor-${i}-${innerI}`) as SVGRectElement;
              const {width} = ((mountedNode.parentElement as SVGRectElement | null) || mountedNode).getBBox();
              const ogX = mountedNode.getAttribute('x');
              mountedNode.setAttribute('x', `${+(ogX || 0) + ((blockWidth) / 2 - width / 2)}`);
              // mountedNode.setAttribute('opacity', '1');
              r(undefined);
            }));
          }
        }, Promise.resolve());
        const certUrls = svg.getElementsByClassName('certificate-url');
        if (certUrls.length) {
          Array.from(certUrls).forEach(url => {
            url.textContent = `${(recordData.BASEURL || window.location.origin).replace(/\/$/, '')}${certificatePath}?id=${record?.id}&form=${form?.formId}`;
          });
        }
        const certQr = svg.getElementById('qrAnchor-certificate');
        if (certQr) {
          const isTransparent = certQr?.classList?.contains?.('transparent-certificate');
          qrcode.toDataURL(`${(recordData.BASEURL || window.location.origin).replace(/\/$/, '')}${certificatePath}?id=${record?.id}&form=${form?.formId}`, {
            margin: isTransparent ? 0 : 1,
            color: {
              dark: '#000000',
              light: isTransparent ? '#00000000' : '#ffffff',
            }
          }, (error, data) => {
            certQr.setAttribute('href', data);
            certQr.setAttribute('xlink:href', data);
          });
        }
        const recordUrls = svg.getElementsByClassName('record-url');
        if (recordUrls.length) {
          Array.from(recordUrls).forEach(url => {
            url.textContent = `${(recordData.BASEURL || window.location.origin).replace(/\/$/, '')}${recordsPath}/${record?.id}`;
          });
        }
        const recordQr = svg.getElementById('qrAnchor-record');
        if (recordQr) {
          const isTransparent = recordQr?.classList?.contains?.('transparent-certificate');
          qrcode.toDataURL(`${(recordData.BASEURL || window.location.origin).replace(/\/$/, '')}${recordsPath}/${record?.id}`, {
            margin: isTransparent ? 0 : 1,
            color: {
              dark: '#000000',
              light: isTransparent ? '#00000000' : '#ffffff',
            }
          }, (error, data) => {
            recordQr.setAttribute('href', data);
            recordQr.setAttribute('xlink:href', data);
          });
        }
        Object.keys(recordData).forEach(key => {
          const links = svg.getElementsByClassName(`notrzr-link-${key}`);
          if (links.length) {
            Array.from(links).forEach((link) => {
              link.setAttribute('href', recordData[key]);
              link.setAttribute('xlink:href', recordData[key]);
            })
          }
        });
        elements.forEach(elem => elem.remove());
      }
    }, Promise.resolve());
    requestAnimationFrame(() => setRendering(false));
  }, [form?.formId, record?.id, recordData]);

  const svg = useMemo(() => {
    return <ReactSVG
        id={`certificate-${record?.id}`}
        src={svgSrc}
        beforeInjection={(svg) => {
          svg.setAttribute('style', form?.maximumWidth && !svgError ? `height:auto;width:min(96vw,${form.maximumWidth}px);display:block;` : `height:calc(100vh - ${ (!form?.verification || form.verification === 'visible') && showReal && !svgError ? '100px' : '5vh'} - 5vh);max-width:90vw;display:block;`);
          svg.setAttribute('width', 'auto');
        }}
        loading={() => <LoadingPage />}
        afterInjection={onInject}
      />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInject]);

  return <>
    {!showReal && <LoadingPage />}
    {showReal && rendering && <LoadingPage className={classes.absolute} />}
    {!initialLoading && !showReal ? <div style={{opacity: 0.001, position: 'absolute', top: 0, left: 0, overflow: 'hidden', width: '100vw', height: '100vh'}}>
      <ReactSVG
        src={svgSrc}
        beforeInjection={(svg) => {
          svg.setAttribute('style', form?.maximumWidth && !svgError ? `height:auto;width:min(96vw,${form.maximumWidth}px);display:block;` : `height:calc(100vh - ${ (!form?.verification || form.verification === 'visible') && showReal && !svgError ? '100px' : '5vh'} - 5vh);max-width:90vw;display:block;`);
          svg.setAttribute('width', 'auto');
        }}
        renumerateIRIElements={false}
        afterInjection={() => {
          setFakeInjected(true);
        }}
      />
    </div> : null}
    {showReal && <div className={`${classes.wrapper} ${form?.maximumWidth && !svgError ? classes.wrapperVertical : ''}`}>
      {svg}
      {showVerification ? <div className={classes.verificationDivider} style={verificationStyle}></div> : null}
      {showVerification ? <div className={classes.verification} style={verificationStyle}>
        <div>
          <div className={classes.verificationInfoBlock}>
            <Typography variant="body1">{t('certificate.verification.check')}&nbsp;
              <NavLink
                className={classes.link}
                style={{color: theme.palette.primary.main}}
                to={`${recordsPath}/${record?.id}`}>
                {t('certificate.verification.metadata')}
              </NavLink>
            </Typography>
          </div>
          <div className={classes.verificationInfoBlock}>
            <Typography variant="body1">{t('certificate.verification.issuer')}&nbsp;{issuerDisplay}</Typography>
          </div>
        </div>
        <div>
          <div className={classes.verificationInfoBlock}>
            <Typography variant="body1">
              {t('certificate.verification.date')}&nbsp;{new Date(record?.timestamp || Date.now()).toLocaleDateString()}
            </Typography>
          </div>
          <div className={classes.verificationInfoBlock}>
            <Typography variant="body1">{t('certificate.verification.transactionVerified')}&nbsp;</Typography><Verified />
          </div>
        </div>
      </div> : null}
    </div>}
  </>;
}
