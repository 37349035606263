import { IAppConfig } from '../models/appConfig';

export default class ConfigStore {
  appConfig: IAppConfig = {};
  configLoaded: boolean = false;

  getAppConfig = async () => {
    if (!this.configLoaded) {
      let customTheme: IAppConfig = {};
      try {
        const themeFile = await fetch('/config/app.json');
        customTheme = await themeFile.json();
      } catch (err) {
        console.error('Failed to load the branding configuration!', err);
      }
      this.appConfig = customTheme;
      this.configLoaded = true;
    }
    return this.appConfig;
  };
}
