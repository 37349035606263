import { useState, useRef, MouseEvent, useEffect } from 'react';
import {
  createStyles,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { random, util } from 'node-forge';
import forge from 'node-forge';
import EthCrypto from 'eth-crypto';
import { useStore } from '../../common/stores/store';
import theme, { ITheme } from '../../common/theme';
import clsx from 'clsx';
import { AttachFile, RemoveCircle } from '@material-ui/icons';
import FileUpload from '../../components/FileUpload';
import { MAX_FILE_SIZE_MB } from '../../components/FileUpload/FileUpload';
import { encryptData } from '../../utils/crypto';
import { ICreateRecord } from '../../common/models/record';
import AuthorizationQr from './AuthorizationQr';
import { useTranslation } from 'react-i18next';
import api from '../../common/api';
import storage, { storageKeys } from '../../common/utils/storage';
import { SocketMessage } from '../../common/api/socket';
import { IFileMetadata } from '../../common/models/file';

const MAX_RECORD_NAME_LENGTH = 50;
const MAX_RECORD_TEXT_LENGTH = 5000;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    formWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
    },
    formControl: {
      marginBottom: theme.spacing(2),
      maxWidth: '540px',
      width: '100%',
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '6px',
      padding: theme.spacing(2),
      '& .MuiInput-underline:before': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
    downloadText: {
      textOverflow: 'ellipsis',
      maxHeight: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 120,
      flex: 1,
    },
    formUrl: {
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
    formGroupWrapper: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    fileUploadWrapper: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: '100%',
      height: '65px',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
    formLabel: {
      padding: theme.spacing(2),
    },
    textField: {
      maxWidth: '100%',
    },
    activateButton: {
      alignSelf: 'flex-start',
      ...theme.mixins.defaultButton,
      ...theme.mixins.wideButton,
    },
  })
);

interface EncryptionResult {
  file: Blob;
  fileKey: string;
  fileIv: string;
  hash: string;
  output: util.ByteStringBuffer;
  tag: util.ByteStringBuffer;
}
interface IProps {
  setRedirectId: React.Dispatch<React.SetStateAction<string>>;
}
const NewRecordForm = ({ setRedirectId }: IProps) => {
  const { t } = useTranslation(['records', 'authorizations', 'common']);
  const classes = useStyles();
  const {
    commonStore: { socketService },
    walletStore,
    recordStore,
    recordStore: { formState, setFormState },
    localWalletStore,
    authorizationStore,
    dialogStore,
  } = useStore();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [urlInputError, setUrlInputError] = useState(false);
  const [uploadEnabled, setUploadEnabled] = useState(true);
  const [checkingRepository, setCheckingRepository] = useState(false);

  useEffect(() => {
    const defaultWallet = storage.getItem(storageKeys.DEFAULT_WALLET);
    if (defaultWallet) {
      const defaultIdx = walletStore.wallets.findIndex(
        (wallet) => wallet.id === defaultWallet
      );
      if (!isNaN(defaultIdx) && defaultIdx >= 0) {
        setFormState({
          ...formState,
          selectedWalletIdx: defaultIdx,
        });
        checkRepository(walletStore.wallets[defaultIdx].ethereumAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRepository = async (address: string) => {
    setCheckingRepository(true);
    const repositoryExists = await api.Repositories.get(address);
    setUploadEnabled(repositoryExists.value.created);
    if (!repositoryExists.value.created) {
      recordStore.setFileToUpload(null);
      formState.recordUrl = '';
      setUrlInputError(false);
      dialogStore.setDialogConfig({
        open: true,
        onClose: dialogStore.closeDialog,
        onCancel: dialogStore.closeDialog,
        onConfirm: dialogStore.closeDialog,
        singleButtonAction: dialogStore.closeDialog,
        title: t('upload_wrong_wallet.title'),
        text: t('upload_wrong_wallet.text'),
        singleButtonText: t('common:button.close'),
      });
    }
    setCheckingRepository(false);
  };

  const handleWalletSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFormState({
      ...formState,
      selectedWalletIdx: Number(event.target.value),
    });
    checkRepository(
      walletStore.wallets[Number(event.target.value)].ethereumAddress
    );
  };

  const handleRecordNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length > MAX_RECORD_NAME_LENGTH) return;
    setFormState({
      ...formState,
      recordName: event.target.value,
    });
  };

  const handleRecordTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length > MAX_RECORD_TEXT_LENGTH) return;
    setFormState({
      ...formState,
      recordText: event.target.value,
    });
  };

  const handleRecordUrlTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      new URL(event.target.value);
      setUrlInputError(false);
    } catch (err) {
      setUrlInputError(true);
    }
    setFormState({
      ...formState,
      recordUrl: event.target.value,
    });
  };

  const uploadFile = async (
    encryptedFile: EncryptionResult,
    recordId: string,
    fileName: string
  ) => {
    const timestamp = Date.now();
    const message = JSON.stringify({
      timestamp,
    });
    const signature = await localWalletStore.wallet?.signMessage(message);
    const buffer = Buffer.concat([
      Buffer.from(encryptedFile.fileIv, 'binary'),
      Buffer.from(encryptedFile.tag.getBytes(), 'binary'),
      Buffer.from(encryptedFile.output.getBytes(), 'binary'),
    ]);

    const blob = new Blob([buffer]);
    const payload = new FormData();
    payload.append('file', blob, fileName);
    payload.append('address', localWalletStore.wallet?.address || '');
    payload.append('signature', signature || '');
    payload.append('timestamp', timestamp.toString());
    const result = await recordStore.uploadFile(
      walletStore.wallets[formState.selectedWalletIdx].ethereumAddress,
      recordId,
      payload
    );
    return result;
  };

  const activateRecord = async () => {
    const payload = {
      author: walletStore.wallets[formState.selectedWalletIdx].ethereumAddress,
      name: formState.recordName,
      data: formState.recordText,
    } as ICreateRecord;

    let encryptedFile;
    if (recordStore.fileToUpload) {
      encryptedFile = await startEncryption(recordStore.fileToUpload);
      payload.file = {
        name: recordStore.fileToUpload.name,
        type: recordStore.fileToUpload.type,
        method: 'SHA2-256',
        size: recordStore.fileToUpload.size.toFixed(),
        hash: encryptedFile.hash,
        uri: formState.recordUrl,
      } as IFileMetadata;
    }
    console.log(encryptedFile);

    const result = await recordStore.activateRecord(payload);
    if (result && result.id) {
      if (payload.file && encryptedFile) {
        setUploadInProgress(true);

        uploadFile(encryptedFile, result.id, payload.file.name);
        setUploadInProgress(false);
      }

      setRedirectId(result.id);
      recordStore.getPendingRecord();
      recordStore.removeFormState();
    }
  };

  const startEncryption = async (file: Blob) => {
    if (authorizationStore.authorization) {
      console.log(localWalletStore.wallet?.publicKey);

      const ret = EthCrypto.cipher.parse(authorizationStore.authorization.key);
      const message = await EthCrypto.decryptWithPrivateKey(
        localWalletStore.wallet?.privateKey || '',
        ret
      );
      console.log(message);

      const fileIv = random.getBytesSync(12);
      return encryptFile(file, forge.util.hexToBytes(message.slice(2)), fileIv);
    }
    throw new Error('No authorization available to encrypt file');
  };

  const encryptFile = (file: Blob, fileKey: string, fileIv: string) => {
    const reader = new FileReader();

    return new Promise<EncryptionResult>((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Failed to encrypt file'));
      };

      reader.onload = () => {
        const binary = reader.result as string;

        const md = forge.md.sha256.create();
        md.update(binary, 'raw');

        const encrypted = encryptData(binary, fileKey, fileIv);

        resolve({
          file,
          fileKey,
          fileIv,
          hash: md.digest().toHex(),
          output: encrypted.output,
          tag: encrypted.tag,
        });
      };

      reader.readAsBinaryString(file);
    });
  };

  const closeAndRefetchAuthorization = () => {
    socketService.unsubscribeById('NEW_RECORD_FORM_AUTHORIZATION');
    authorizationStore.setAuthorizationProgress('PENDING');
    authorizationStore.fetchAuthorization();
    dialogStore.closeDialog();
  };

  const showQRCode = () => {
    const newAuthorizationSocket = (data: SocketMessage<string>) => {
      console.log('hello thios is', data.type);
      switch (data.type) {
        case 'AUTHORIZATION_RECEIVED':
          authorizationStore.setAuthorizationProgress('PROGRESS');
          break;
        case 'AUTHORIZATION_SUCCESS':
          authorizationStore.setAuthorizationProgress('SUCCESS');
          break;
        case 'AUTHORIZATION_FAILED':
          authorizationStore.setAuthorizationProgress('FAILED');
          break;
        default:
          break;
      }
    };
    socketService.subscribeWithId(
      newAuthorizationSocket,
      'NEW_RECORD_FORM_AUTHORIZATION'
    );
    socketService.instance.send(
      JSON.stringify({
        type: 'WALLET',
        message: localWalletStore.wallet?.address,
      })
    );
    dialogStore.setDialogConfig({
      open: true,
      onClose: closeAndRefetchAuthorization,
      onCancel: closeAndRefetchAuthorization,
      onConfirm: closeAndRefetchAuthorization,
      title: t('authorizations:qr_scan.title'),
      component: () => (
        <AuthorizationQr
          publicKey={
            localWalletStore.wallet ? localWalletStore.wallet.publicKey : ''
          }
          address={
            localWalletStore.wallet ? localWalletStore.wallet.address : ''
          }
        />
      ),
      singleButtonAction: closeAndRefetchAuthorization,
      singleButtonText: t('common:button.close'),
    });
  };

  const openWarning = () => {
    dialogStore.setDialogConfig({
      open: true,
      onClose: dialogStore.closeDialog,
      onCancel: dialogStore.closeDialog,
      onConfirm: selectFile,
      title: t('upload_warning.title'),
      text: t('upload_warning.text'),
      cancelButtonText: t('common:button.close'),
      confirmButtonText: t('common:button.proceed'),
    });
  };

  const selectFile = () => {
    if (!fileInputRef || !fileInputRef.current) {
      dialogStore.closeDialog();
      return;
    }
    fileInputRef.current.value = '';
    fileInputRef.current.click();
    dialogStore.closeDialog();
  };

  const openFilePicker = async () => {
    if (
      recordStore.fileToUpload ||
      !fileInputRef ||
      !fileInputRef.current ||
      !localWalletStore.wallet ||
      !uploadEnabled
    )
      return;
    if (
      !authorizationStore.authorization ||
      authorizationStore.authorizationLoading
    ) {
      showQRCode();
      return;
    }
    openWarning();
  };

  const unselectFile = (event: MouseEvent<SVGSVGElement>) => {
    event?.stopPropagation();
    recordStore.setFileToUpload(null);
    formState.recordUrl = '';
    setUrlInputError(false);
  };

  return (
    <form className={classes.formWrapper} autoComplete="off">
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.formLabel} id="wallet-for-record-label">
          {t('new_record.wallet')}
        </InputLabel>
        <Select
          labelId="wallet-for-record-label"
          id="wallet-select"
          value={formState.selectedWalletIdx}
          renderValue={(value: unknown) =>
            walletStore.wallets[Number(value)].name
          }
          onChange={handleWalletSelect}
        >
          {walletStore.wallets.map((wallet, idx) => (
            <MenuItem key={wallet.id} value={idx}>
              {`${wallet.name} - ${wallet.ethereumAddress}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        color="primary"
        size={'small'}
        value={formState.recordName}
        onChange={handleRecordNameChange}
        label={t('new_record.name', {
          currentCount: formState.recordName.length,
          maxCount: MAX_RECORD_NAME_LENGTH,
        })}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        InputLabelProps={{
          className: classes.formLabel,
        }}
        label={t('new_record.text', {
          currentCount: formState.recordText.length,
          maxCount: MAX_RECORD_TEXT_LENGTH,
        })}
        value={formState.recordText}
        onChange={handleRecordTextChange}
        multiline
        size={'small'}
        rows={20}
        className={clsx(classes.formControl, classes.textField)}
      />

      <div className={classes.formGroupWrapper}>
        <div
          className={classes.fileUploadWrapper}
          onClick={() => openFilePicker()}
          style={!uploadEnabled ? { color: theme.palette.grey[500] } : {}}
        >
          <AttachFile fontSize={'large'} style={{ cursor: 'pointer' }} />
          <Typography
            variant={'h6'}
            style={{ cursor: 'pointer' }}
            className={classes.downloadText}
          >
            {' '}
            {recordStore.fileToUpload
              ? recordStore.fileToUpload.name
              : t('new_record.attach_file_text', {
                  maxFileSize: MAX_FILE_SIZE_MB,
                })}
          </Typography>

          {recordStore.fileToUpload && (
            <RemoveCircle
              style={{
                marginLeft: '.5rem',
                marginRight: '.5rem',
                cursor: 'pointer',
              }}
              onClick={unselectFile}
            />
          )}
          <FileUpload fileInputRef={fileInputRef} />
        </div>
        {recordStore.fileToUpload && (
          <TextField
            color="primary"
            size={'small'}
            value={formState.recordUrl}
            onChange={handleRecordUrlTextChange}
            label={t('record.url')}
            variant="standard"
            className={clsx(classes.formControl, classes.formUrl)}
            InputLabelProps={{
              className: classes.formLabel,
            }}
            error={urlInputError}
            helperText={urlInputError ? t('new_record.error.invalid_url') : ''}
          />
        )}
      </div>

      {checkingRepository || uploadInProgress ? (
        <CircularProgress color={'primary'} />
      ) : (
        <Button
          className={classes.activateButton}
          variant="contained"
          color="primary"
          onClick={activateRecord}
          disabled={
            !(formState.recordName && formState.recordText && !urlInputError)
          }
        >
          {t('common:button.activate')}
        </Button>
      )}
    </form>
  );
};

export default observer(NewRecordForm);
