import { useEffect, useState } from 'react';
import preval from 'preval.macro';
import env from '@beam-australia/react-env';
import { observer } from 'mobx-react-lite';
import { Switch, Route } from 'react-router-dom';
import ConfirmEmail from '../components/ConfirmEmail';
import Search from '../features/Search';
import { Records, RecordDetails, NewRecord } from '../features/Records';
import Wallets from '../features/Wallets/Wallets';
import {
  searchPath,
  walletsPath,
  recordsPath,
  newRecordPath,
  recordDetailsPath,
  versionPath, newFormPath, certWalletsPath, certificatesPath, certNewPath, certSearchPath, pokPath,
} from './constants/routes';
import { useStore } from './stores/store';
import api from './api';
import { IVersion } from './models/version';
import UserNotConfirmed from "../components/UserNotConfirmed";
import UserSignup from "../components/UserSignup";
import NewCertificateRecord from "../features/CertificateForm/NewCertificateRecord";
import CertificateList from "../features/Certificates/List";
import {CertificateForms} from "../features/Certificates/CertificateForms";
import CertificateSearch from "../features/Certificates/Search";
import POK from "../features/POK";

const Router = () => {
  const {
    userStore: { loggedIn, user, userChecks },
  } = useStore();

  const emailUnconfirmed = loggedIn && user && !user.profile.email_verified;
  const userNew = loggedIn && user && userChecks?.isNewUser;
  const userUnconfirmed = loggedIn && user && userChecks && userChecks?.state?.stateId !== 3;

  if (emailUnconfirmed) {
    return (
      <Route>
        <ConfirmEmail />
      </Route>
    );
  }
  if (userNew) {
    return (
      <Route>
        <UserSignup />
      </Route>
    );
  }
  if (userUnconfirmed) {
    return (
      <Route>
        <UserNotConfirmed />
      </Route>
    );
  }

  return (
    <Switch>
      <Route path={searchPath}>
        <Search />
      </Route>
      <Route path={pokPath}>
        <POK />
      </Route>
      <Route path={walletsPath}>
        <Wallets />
      </Route>
      <Route path={certWalletsPath}>
        <Wallets disableSelection />
      </Route>
      <Route exact path={recordsPath}>
        <Records />
      </Route>
      <Route exact path={certificatesPath}>
        <CertificateList />
      </Route>
      <Route exact path={certSearchPath}>
        <CertificateSearch />
      </Route>
      <Route exact path={certNewPath}>
        <CertificateForms />
      </Route>
      <Route exact path={newRecordPath}>
        <NewRecord />
      </Route>
      <Route exact path={newFormPath}>
        <NewCertificateRecord />
      </Route>
      <Route path={recordDetailsPath}>
        <RecordDetails />
      </Route>
      <Route path={versionPath}>
        <Version />
      </Route>
      <Route>
        <Search />
      </Route>
    </Switch>
  );
};

export default observer(Router);

const Version = () => {
  const [versionInfo, setVersionInfo] = useState<IVersion | null>(null);

  const buildVersion = {
    name: 'Frontend build version',
    version: env('VERSION'),
  };
  const buildDate = {
    name: 'Frontend build date',
    version: preval`module.exports = new Date().toLocaleString();`,
  };
  useEffect(() => {
    api.WebConfig.getVersion()
      .then((versionResponse) => {
        setVersionInfo(versionResponse.value);
      })
      .catch((_err) => {});
  }, []);
  return (
    <pre>
      {JSON.stringify(buildVersion, undefined, 2)},
      <br />
      {JSON.stringify(buildDate, undefined, 2)},
      <br />
      {versionInfo ? JSON.stringify(versionInfo, undefined, 2) : 'Loading'}
    </pre>
  );
};
