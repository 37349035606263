import { action, makeObservable, observable } from 'mobx';
import { IError } from '../models/error';
import errorNotify from '../utils/notifications/errorNotify';
import { IAuthorization } from '../models/authorization';
import api from '../api';
import { store } from './store';
import i18next from 'i18next';

export default class AuthorizationStore {
  authorization: IAuthorization | null = null;
  authorizationLoading = true;
  authorizationProgress: 'PENDING' | 'PROGRESS' | 'SUCCESS' | 'FAILED' =
    'PENDING';
  error: IError | null = null;

  constructor() {
    makeObservable(this, {
      authorization: observable,
      authorizationLoading: observable,
      authorizationProgress: observable,
      error: observable,
      setError: action,
      setAuthorization: action,
      setAuthorizationLoading: action,
    });
  }

  setError = (error: IError | null) => {
    this.error = error;
  };

  setAuthorization = (authorization: IAuthorization | null) => {
    this.authorization = authorization;
  };

  setAuthorizationLoading = (authorizationLoading: boolean) => {
    this.authorizationLoading = authorizationLoading;
  };

  setAuthorizationProgress = (
    authorizationProgress: 'PENDING' | 'PROGRESS' | 'SUCCESS' | 'FAILED'
  ) => {
    this.authorizationProgress = authorizationProgress;
  };

  fetchAuthorization = async () => {
    this.setAuthorizationLoading(true);
    if (
      this.authorization &&
      new Date(this.authorization.expiry).getTime() < Date.now()
    ) {
      this.setAuthorization(null);
    }
    if (store.localWalletStore.wallet) {
      try {
        const value = {
          timestamp: Date.now(),
        };
        const authorization = await api.Authorizations.fetch(
          store.localWalletStore.wallet.address,
          value,
          {
            address: store.localWalletStore.wallet.address,
            signature: await store.localWalletStore.wallet.signMessage(
              JSON.stringify(value)
            ),
          }
        );
        this.setAuthorization(authorization.value);
      } catch (err) {
        console.error(err);
        errorNotify(i18next.t('error.fetch_authorization_failed'));
      }
    }
    this.setAuthorizationLoading(false);
  };
}
