import { createTheme, Theme } from '@material-ui/core/styles';
import createMixins, { Mixins } from '@material-ui/core/styles/createMixins';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { IAppConfig, IThemeOptions } from './models/appConfig';

declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    wrapper: CSSProperties;
    fullstopWrapper: CSSProperties;
    defaultButton: CSSProperties;
    wideButton: CSSProperties;
    overflowEllipsis: CSSProperties;
    verticalAlign: CSSProperties;
  }
}
export interface ITheme extends Theme {
  mixins: Mixins;
  typography: Record<keyof Theme['typography'], any>;
}

const { breakpoints, spacing } = createTheme();

const mixins = createMixins(breakpoints, spacing, {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullstopWrapper: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  defaultButton: {
    width: '148px',
    fontSize: '1.125em',
    fontWeight: 700,
  },
  wideButton: {
    width: '184px',
  },
  overflowEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },
});

const themeOptions: IThemeOptions = {
  mixins,
  palette: {
    primary: {
      main: '#87189D',
    },
  },
  typography: {
    fontFamily: '"Nunito Sans", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
};

const theme = createTheme(themeOptions);

export const getCustomTheme = (appConfig: IAppConfig) => {
  let customThemeOptions = themeOptions;
  try {
    customThemeOptions = {
      ...themeOptions,
      ...appConfig.style,
    };
    return createTheme(customThemeOptions);
  } catch {
    return null;
  }
};

export default theme;
