import Sockette from 'sockette';

export type SocketMessageType =
  | 'PING'
  | 'WALLET'
  | 'AUTHORIZATION_RECEIVED'
  | 'AUTHORIZATION_SUCCESS'
  | 'AUTHORIZATION_FAILED'
  | string;

export interface AccessTokenMessage {
  assetId: string;
  token: string;
}

export interface SocketMessage<T> {
  type: SocketMessageType;
  message: T;
}

export interface SocketProps {
  url: string;
}

const heartbeat = (socket: Sockette, pingTimeout: NodeJS.Timeout) => {
  clearTimeout(pingTimeout);

  return setTimeout(() => {
    socket.close();
    socket.reconnect();
  }, 30000 + 1000);
};

export class SocketService {
  instance: Sockette;
  observers: { fn: (data: SocketMessage<any>) => void; id?: string }[] = [];

  constructor(url: string) {
    this.instance = this.create({ url });
  }

  /**
   * Subscribe to observe Websocket events.
   * @param fn Function.
   */
  subscribe(fn: (data: SocketMessage<any>) => void) {
    console.log('subscribed');
    this.observers.push({ fn });
  }

  /**
   * Unsubscribe to stop listening.
   * @param fn Function.
   */
  unsubscribe(fn: (data: SocketMessage<any>) => void) {
    this.observers = this.observers.filter(
      (subscriber) => subscriber.fn !== fn
    );
  }

  /**
   * Subscribe via id to observe Websocket events.
   * @param fn Function.
   * @param string id ID for the observer.
   */
  subscribeWithId(fn: (data: SocketMessage<any>) => void, id: string) {
    console.log('subscribed with id');

    this.observers.push({ fn, id });
  }

  /**
   * Unsubscribe by id to stop listening.
   * @param string id ID for the observer.
   */
  unsubscribeById(id: string) {
    console.log('unsubscribed with id');

    this.observers = this.observers.filter(
      (subscriber) => subscriber.id !== id
    );
  }

  /**
   * Broadcast data to all listeners.
   * @param {*} data Data.
   */
  broadcast(data: SocketMessage<any>) {
    console.log('broadcasting', data);
    this.observers.forEach((subscriber) => subscriber.fn(data));
  }

  create = ({ url }: SocketProps) => {
    let pingTimeout: NodeJS.Timeout;
    const socket = new Sockette(url, {
      onopen: (e) => {
        pingTimeout = heartbeat(socket, pingTimeout);
        console.log('connected');
      },
      onmessage: (e) => {
        const data: SocketMessage<any> = JSON.parse(e.data);
        console.log('received data', data);
        switch (data.type) {
          case 'PING':
            pingTimeout = heartbeat(socket, pingTimeout);
            socket.send(
              JSON.stringify({
                type: 'PONG',
                message: '',
              })
            );
            break;
          default:
            this.broadcast(data);
            break;
        }
      },
    });
    return socket;
  };
}
