import { createContext, useContext } from 'react';
import CommonStore from './commonStore';
import ConfigStore from './configStore';
import NavigationStore from './navigationStore';
import UserStore from './userStore';
import RecordStore from './recordStore';
import CertificatesStore from './certificatesStore';
import WalletStore from './walletStore';
import DialogStore from './dialogStore';
import LocalWalletStore from './localWalletStore';
import AuthorizationStore from './authorizationStore';

interface Store {
  commonStore: CommonStore;
  configStore: ConfigStore;
  userStore: UserStore;
  navigationStore: NavigationStore;
  recordStore: RecordStore;
  certificateStore: CertificatesStore;
  walletStore: WalletStore;
  dialogStore: DialogStore;
  localWalletStore: LocalWalletStore;
  authorizationStore: AuthorizationStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  configStore: new ConfigStore(),
  userStore: new UserStore(),
  navigationStore: new NavigationStore(),
  recordStore: new RecordStore(),
  certificateStore: new CertificatesStore(),
  walletStore: new WalletStore(),
  dialogStore: new DialogStore(),
  localWalletStore: new LocalWalletStore(),
  authorizationStore: new AuthorizationStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
