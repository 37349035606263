import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  makeStyles,
  createStyles,
  Button,
  CircularProgress,
  IconButton,
  Collapse,
  Typography,
} from '@material-ui/core';
import QRCode from 'qrcode.react';
import { ITheme } from '../../common/theme';
import { useStore } from '../../common/stores/store';
import LoginRequired from '../../components/LoginRequired';
import { AddCircle, DeleteForever } from '@material-ui/icons';
import InfoBox from '../../components/InfoBox';
import WalletQr from './WalletQr';
import ErrorInfoBox from '../../components/ErrorInfoBox';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import storage, { storageKeys } from '../../common/utils/storage';
let clearTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: { ...theme.mixins.verticalAlign, height: '100%' },
    wrapper: { ...theme.mixins.wrapper, minHeight: '80%' },
    wallet: {
      marginBottom: theme.spacing(2),
    },
    walletButton: {
      ...theme.mixins.defaultButton,
      width: 450,
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.down('md')]: {
        width: 368,
      },
      [theme.breakpoints.down('xs')]: {
        width: 184,
      },
    },
    walletButtonLabel: {
      ...theme.mixins.overflowEllipsis,
      display: 'block',
    },
    walletButtonExpanded: {
      borderRadius: '4px 4px 0 0',
      boxShadow: 'none',
    },
    walletDetailsWrapper: {
      width: 450,
      border: '1px solid',
      borderTop: 0,
      borderRadius: '0 0 4px 4px',
      borderColor: theme.palette.primary.main,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5em 0',
      [theme.breakpoints.down('md')]: {
        width: 368,
      },
      [theme.breakpoints.down('xs')]: {
        width: 184,
      },
    },
    walletDetailsAddress: {
      wordBreak: 'break-all',
      padding: '0 0.8em',
    },
  })
);

const Wallet = ({ disableSelection }: {disableSelection?: boolean}) => {
  const { t } = useTranslation(['wallets', 'common']);
  const classes = useStyles();
  const { userStore, walletStore, dialogStore } = useStore();
  const previousWalletCount = useRef<number | undefined>(undefined);
  const location = useLocation<{ comingFromNewRecordForm?: boolean }>();
  const [openWalletId, setOpenWalletId] = useState('');
  const [savedDefaultWallet, setSavedDefaultWallet] = useState(
    storage.getItem(storageKeys.DEFAULT_WALLET) || ''
  );

  useEffect(() => {
    if (userStore.loggedIn) {
      walletStore.setNewWalletData(null);
      walletStore.loadWalletsSorted();
      if (location?.state?.comingFromNewRecordForm) {
        walletStore.initNewWallet();
      }
    }
  }, [
    walletStore,
    userStore.loggedIn,
    location?.state?.comingFromNewRecordForm,
  ]);

  useEffect(() => {
    if (walletStore.wallets.length > 0 && savedDefaultWallet === '') {
      storage.setItem(storageKeys.DEFAULT_WALLET, walletStore.wallets[0].id);
      setSavedDefaultWallet(walletStore.wallets[0].id);
    }
  }, [walletStore.wallets, savedDefaultWallet]);

  // Poll wallets and compare count to check if a new wallet has been added
  useEffect(() => {
    if (walletStore.newWalletData) {
      if (previousWalletCount.current === undefined) {
        previousWalletCount.current = walletStore.wallets.length;
      }

      clearTimer = setTimeout(() => {
        const currentWalletCount = walletStore.wallets.length;
        if (
          previousWalletCount.current !== undefined &&
          currentWalletCount > previousWalletCount.current
        ) {
          walletStore.setNewWalletData(null);
          previousWalletCount.current = currentWalletCount;
        } else {
          previousWalletCount.current = currentWalletCount;
          walletStore.loadWalletsSorted();
        }
      }, 1200);
      return () => clearTimeout(clearTimer);
    }
  }, [walletStore, walletStore.newWalletData, walletStore.wallets]);

  useEffect(() => {
    if (
      previousWalletCount.current !== undefined &&
      walletStore.wallets.length > previousWalletCount.current
    ) {
      previousWalletCount.current = walletStore.wallets.length;
      walletStore.setNewWalletData(null);
    }
  }, [walletStore, walletStore.wallets]);

  const deleteWallet = (id: string) => {
    dialogStore.setDialogConfig({
      open: true,
      onClose: dialogStore.closeDialog,
      onCancel: dialogStore.closeDialog,
      onConfirm: () => {
        return walletStore.deleteWallet(id);
      },
      title: t('delete_confirmation.title'),
      text: t('delete_confirmation.description'),
    });
  };

  if (!userStore.loggedIn) {
    return <LoginRequired />;
  }

  if (walletStore.error) {
    const { error } = walletStore;
    return <ErrorInfoBox title={error.title} subtitle={error.message} />;
  }

  const walletsLoadingInBackground =
    walletStore.walletsLoading && walletStore.newWalletData;

  return (
    <div className={classes.wrapper}>
      {walletStore.walletsLoading && !walletsLoadingInBackground ? (
        <div className={classes.verticalAlign}>
          <CircularProgress color={'primary'} />
        </div>
      ) : (
        <>
          {walletStore.wallets.map((wallet) => {
            return (
              <span key={wallet.id} className={classes.wallet}>
                <span style={{ position: 'relative' }}>
                  <Button
                    variant={
                      !disableSelection && (openWalletId === wallet.id ||
                      storage.getItem(storageKeys.DEFAULT_WALLET) === wallet.id)
                        ? 'contained'
                        : 'outlined'
                    }
                    color={'primary'}
                    className={clsx(
                      classes.walletButton,
                      openWalletId === wallet.id && classes.walletButtonExpanded
                    )}
                    classes={{ label: classes.walletButtonLabel }}
                    onClick={() => {
                      if (openWalletId === wallet.id) {
                        setOpenWalletId('');
                      } else {
                        setOpenWalletId(wallet.id);
                        if (!disableSelection) storage.setItem(storageKeys.DEFAULT_WALLET, wallet.id);
                      }
                    }}
                  >
                    {wallet.name}
                  </Button>
                  <IconButton
                    style={{
                      position: 'absolute',
                      paddingTop: 6,
                      paddingBottom: 6,
                    }}
                    onClick={() => deleteWallet(wallet.id)}
                  >
                    <DeleteForever color="primary" />
                  </IconButton>
                </span>

                <Collapse
                  in={openWalletId === wallet.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <div className={classes.walletDetailsWrapper}>
                    {!disableSelection && <Typography
                      color="primary"
                      align="center"
                      style={{fontSize: 14}}
                    >
                      {t('details.default_wallet')}
                    </Typography>}
                    <Typography
                      align="center"
                      className={classes.walletDetailsAddress}
                    >
                      {wallet.ethereumAddress}
                    </Typography>
                    <QRCode
                      value={JSON.stringify({
                        T: 'V',
                        A: wallet.ethereumAddress,
                      })}
                      includeMargin={true}
                      size={150}
                    />
                    <Typography align="center">
                      {t('details.scan_explanation')}
                    </Typography>
                  </div>
                </Collapse>
              </span>
            );
          })}
          {walletStore.wallets.length ? (
            <Button
              variant="outlined"
              color="primary"
              className={classes.walletButton}
              endIcon={<AddCircle />}
              onClick={walletStore.initNewWallet}
              disabled={walletStore.loadingNewWalletData}
            >
              {t('wallets.add_new')}
            </Button>
          ) : (
            <div className={classes.verticalAlign}>
              <InfoBox
                title={t('no_wallets.title')}
                subtitle={t('no_wallets.subtitle')}
                leftButton={{
                  variant: 'contained',
                  onClick: walletStore.initNewWallet,
                  text: t('common:button.add'),
                  disabled: walletStore.loadingNewWalletData,
                }}
              />
            </div>
          )}
        </>
      )}
      {walletStore.walletsLoading && !walletsLoadingInBackground ? null : (
        <WalletQr />
      )}
    </div>
  );
};

export default observer(Wallet);
