import { IFileMetadata } from './file';

export interface ICertificateRecord {
  "id": string;
  "creationTime": string;
  "formId": string;
  "mainProperty": string;
}

export interface IRecord {
  id: string;
  bookmark: boolean;
  name: string;
  data: string;
  author: string;
  text: string;
  isAttachmentUploaded?: boolean;
  file: IFileMetadata | null;
  documentDbHash: string;
  blockchainHash: string;
  timestamp: Date;
  onCreated: Date;
  onUpdated: Date;
  state?: RecordState;
}

export interface ICertificateFormField {
  id: string, type: string, label: string, min: string, max: string; main?: boolean;
}

export interface ICertificateForm {
  constants: Array<Record<string, string>>;
  formId: string;
  certUrl: string;
  maximumWidth: string;
  issuer: string;
  max?: string;
  min?: string;
  verification?: string;
  mandatory: ICertificateFormField[];
  optional: ICertificateFormField[];
}

export interface ICreateRecord {
  name: string;
  data: string;
  author: string;
  file: IFileMetadata | null;
}
export interface ICreateCertificate {
  name: string;
  data: string;
  author: string;
  formId: string;
  mainProperty: string;
}

export interface IFormState {
  selectedWalletIdx: number;
  recordName: string;
  recordText: string;
  recordUrl: string;
}

export enum RecordLinkType {
  FROM = 'FROM',
  TO = 'TO',
  INVITATION = 'INVITATION',
  SIGNATURE = 'SIGNATURE',
}

export interface IRecordLink {
  id: string;
  name: string;
  type: RecordLinkType;
}

enum RecordState {
  RECEIVED = 'Received',
  SUBMITTED = 'Submitted',
  COMMITTED = 'Committed',
  CONFIRMED = 'Confirmed',
  TX_SIGNATURE_SUBMITTED = 'TxSignatureSubmitted',
  REVOKED_BY_TX_SERVER = 'RevokedByTxServer',
  REVOKED_BY_USER = 'RevokedByUser',
  REVOKED_BY_BLOCKCHAIN = 'RevokedByBlockchain',
}

export const DEFAULT_RECORDS_PAGE_SIZE = 5;
