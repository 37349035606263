import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../common/stores/store';
import { ITheme } from '../../common/theme';
import InfoBox from '../InfoBox';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    wrapper: theme.mixins.fullstopWrapper,
  })
);
const LoginRequired = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userStore } = useStore();
  const login = () => {
    userStore.login();
  };
  return (
    <div className={classes.wrapper}>
      <InfoBox
        title={t('error.not_logged_in.title')}
        subtitle={t('error.not_logged_in.subtitle')}
        leftButton={{
          variant: 'contained',
          onClick: login,
          text: t('button.login'),
        }}
        rightButton={{
          variant: 'outlined',
          onClick: login,
          text: t('button.register'),
        }}
      />
    </div>
  );
};
export default LoginRequired;
