import { makeObservable, observable, action } from 'mobx';
import api from '../api';
import {
  UserManager as OidcUserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client';
import { IUser, User } from '../models/user';
import { store } from './store';
import errorNotify from '../utils/notifications/errorNotify';
import storage, { storageKeys } from '../utils/storage';
import { loginRedirectUri, postlogoutRedirectUri } from '../constants/routes';
import i18next from 'i18next';
import { IWebConfig } from "../models/discoveryConfig";

class UserManager {
  private static instance: OidcUserManager;
  private constructor() {}

  public static initialize(userManagerSettings: UserManagerSettings): void {
    UserManager.instance = new OidcUserManager(userManagerSettings);
  }

  public static getInstance(): OidcUserManager {
    if (!UserManager.instance) {
      throw new Error(
        'OidcUserManager instance was accessed without being initialized.'
      );
    }
    return UserManager.instance;
  }
}

export default class UserStore {
  loggedIn = false;
  user: User | null = null;
  userChecks: IUser | null = null;

  constructor() {
    makeObservable(this, {
      loggedIn: observable,
      userChecks: observable,
      loadUser: action,
      setLoggedIn: action,
    });
  }

  initUserManager = async () => {
    const value = store.commonStore.discovery as IWebConfig;

    UserManager.initialize({
      authority: value.endpoints.authApi,
      client_id: value.authConfig.clientId,
      redirect_uri: loginRedirectUri,
      post_logout_redirect_uri: postlogoutRedirectUri,
      response_type: value.authConfig.responseType,
      scope: value.authConfig.scopes,
      userStore: new WebStorageStateStore({ store: localStorage }),
    });
  };

  setLoggedIn = (loggedIn: boolean) => {
    this.loggedIn = loggedIn;
  };

  login = () => {
    store.commonStore.setAppLoading(true);
    storage.setItem(storageKeys.POST_IS_REDIRECT_URL, window.location.pathname);
    const userManager = UserManager.getInstance();
    userManager.clearStaleState().then(() => userManager.signinRedirect());
  };

  logout = () => {
    store.commonStore.setAppLoading(true);
    store.commonStore.setAccessToken(undefined);
    this.user = null;
    this.setLoggedIn(false);
    store.localWalletStore.removeLocalWallet();
    store.authorizationStore.setAuthorization(null);
    store.recordStore.removeFormState();
    UserManager.getInstance().signoutRedirect();
  };

  checkUser = async () => {
    store.commonStore.setAppLoading(true);
    const value = await api.Wlm.getUser();
    this.userChecks = value as any;
    return this.userChecks;
  }

  loadUser = async () => {
    this.user = await UserManager.getInstance().getUser();
    if (this.user) {
      this.setLoggedIn(true);
    } else {
      this.setLoggedIn(false);
    }
    return this.user;
  };

  removeUser = async () => {
    await UserManager.getInstance().removeUser();
  };

  loginCallback = async () => {
    await UserManager.getInstance().signinCallback();
  };

  sendEmailConfirmation = async () => {
    try {
      await api.User.sendConfirmationEmail();
    } catch (_err) {
      errorNotify(i18next.t('error.email_confirmation_send'));
    }
  };
}
