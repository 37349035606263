export const { protocol, hostname, port = '' } = window.location;
export const baseRedirectUri = `${protocol}//${hostname}${
  port ? `:${port}` : ''
}`;
export const identityServerRedirectPath = '/signin-oidc';
export const loginRedirectUri = `${baseRedirectUri}${identityServerRedirectPath}`;
export const postlogoutRedirectUri = `${baseRedirectUri}`;

export const emailConfirmationPath = '/confirm-email';
export const searchPath = '/search';
export const certificatePath = `/certificate`;
export const certificatesPath = `/certificates`;
export const pokPath = `/pok`;
export const walletsPath = '/wallets';
export const certWalletsPath = `${certificatesPath}/wallets`;
export const recordsPath = '/records';
export const certSearchPath = `${certificatesPath}/search`;
export const certNewPath = `${certificatesPath}/new`;
export const newRecordPath = `${recordsPath}/new`;
export const newFormPath = `${certificatesPath}/:id/new`;
export const getNewFormPath = (id: string) => `${certificatesPath}/${id}/new`;
export const recordDetailsPath = `${recordsPath}/:id`;
export const versionPath = '/version';

export const imprintUrl = 'https://notardec.com/imprint';
