import i18next from 'i18next';
import { makeAutoObservable } from 'mobx';
import api from '../api';
import { IError } from '../models/error';
import {
  DEFAULT_RECORDS_PAGE_SIZE,
  ICreateCertificate,
  ICertificateRecord,
} from '../models/record';
import errorNotify from '../utils/notifications/errorNotify';

export default class CertificateStore {
  certificates: ICertificateRecord[] = [];
  foundCertificates: ICertificateRecord[] = [];
  totalCertificates = 0;
  totalFoundCertificates = 0;
  certificatesLoading = false;
  searchOngoing = false;
  advancedSearchOngoing = false;
  error: IError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError = (error: IError | null) => {
    this.error = error;
  };

  setCertificates = (records: ICertificateRecord[]) => {
    this.certificates = records;
  };

  setFoundCertificates = (records: ICertificateRecord[]) => {
    this.foundCertificates = records;
  };

  setTotalCertificates = (totalCount: number) => {
    this.totalCertificates = totalCount;
  };

  setTotalFoundCertificates = (totalCount: number) => {
    this.totalFoundCertificates = totalCount;
  };

  setCertificatesLoading = (recordsLoading: boolean) => {
    this.certificatesLoading = recordsLoading;
  };

  setSearchOngoing = (searchOngoing: boolean) => {
    this.searchOngoing = searchOngoing;
  };

  setAdvancedSearchOngoing = (searchOngoing: boolean) => {
    this.advancedSearchOngoing = searchOngoing;
  };

  loadCertificates = async (limit = DEFAULT_RECORDS_PAGE_SIZE, skip = 0) => {
    try {
      this.setError(null);
      this.setCertificatesLoading(true);
      const { value, meta } = await api.Records.certificates(limit, skip);
      this.setCertificates(value);
      if (meta && meta.totalCount) {
        this.setTotalCertificates(meta.totalCount);
      }
    } catch (error) {
      this.setError({
        title: i18next.t('title.error'),
        message: i18next.t('error.load_record'),
      });
    } finally {
      this.setCertificatesLoading(false);
      this.setSearchOngoing(false);
    }
  };

  createCertificate = async (
    record: ICreateCertificate | FormData
  ): Promise<ICertificateRecord | undefined> => {
    try {
      this.setCertificatesLoading(true);
      const { value } = await api.Records.createCertificate(record);
      return value;
    } catch (error) {
      errorNotify(i18next.t('error.record_activation'));
    } finally {
      this.setCertificatesLoading(false);
    }
  };

  searchRecords = async (
    searchValue: string,
    limit = DEFAULT_RECORDS_PAGE_SIZE,
    skip = 0
  ) => {
    try {
      this.setSearchOngoing(true);
      const { value, meta } = await api.Records.certificates(limit, skip, searchValue);
      this.setCertificates(value);
      if (meta && meta.totalCount) {
        this.setTotalCertificates(meta.totalCount);
      }
    } catch (error) {
      errorNotify(i18next.t('error.record_search'));
    } finally {
      this.setSearchOngoing(false);
    }
  };

  searchRecordsAdvanced = async (
    {limit, skip, mainProperty, dateTo, dateFrom, id}: {
      skip?: number;
      limit?: number;
      search?: string;
      mainProperty?: string;
      dateFrom?: string;
      dateTo?: string;
      id?: string;
    }
  ) => {
    try {
      this.setAdvancedSearchOngoing(true);
      const { value, meta } = await api.Records.certificatesSearch({limit, skip, mainProperty, dateTo, dateFrom, id});
      this.setFoundCertificates(value);
      if (meta && meta.totalCount) {
        this.setTotalFoundCertificates(meta.totalCount);
      }
    } catch (error) {
      errorNotify(i18next.t('error.record_search'));
    } finally {
      this.setAdvancedSearchOngoing(false);
    }
  };
}
