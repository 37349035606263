import React, { Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme, { getCustomTheme } from './common/theme';
import { store, StoreContext, useStore } from './common/stores/store';
import Container from './common/Container';
import { Router as BrowserRouter } from 'react-router';
import { Theme } from '@material-ui/core';
import './lang/i18n';

function App(): JSX.Element {
  const [loadedTheme, setLoadedTheme] = React.useState<Theme>(theme);
  const { configStore } = useStore();

  React.useEffect(() => {
    const loadTheme = async () => {
      const appConfig = await configStore.getAppConfig();
      const customTheme = getCustomTheme(appConfig);
      if (appConfig.config?.title) {
        document.title = appConfig.config.title;
      }
      const faviconObject = document.getElementById('favicon');
      if (
        appConfig.config?.favicon &&
        faviconObject instanceof HTMLLinkElement
      ) {
        faviconObject.href = `/${appConfig.config.favicon}`;
      }
      setLoadedTheme(customTheme || theme);
    };
    loadTheme();
  }, [configStore]);

  return (
    <Suspense
      fallback={
        <div style={{ backgroundColor: theme.palette.background.default }} />
      }
    >
      <ThemeProvider theme={loadedTheme}>
        <CssBaseline />
        <BrowserRouter history={store.navigationStore.history}>
          <StoreContext.Provider value={store}>
            <Container />
          </StoreContext.Provider>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
