import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../common/stores/store';
import { ITheme } from '../../common/theme';
import InfoBox from '../InfoBox';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    wrapper: theme.mixins.fullstopWrapper,
  })
);

const UserNotConfirmed = () => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const { commonStore, userStore } = useStore();

  const check = async () => {
    commonStore.setAppLoading(true);
    await userStore.checkUser();
    commonStore.setAppLoading(false);
  };

  return (
    <div className={classes.wrapper}>
      <InfoBox
        title={t('error.user_not_confirmed.title')}
        subtitle={t('error.user_not_confirmed.subtitle')}
        leftButton={{
          variant: 'contained',
          onClick: check,
          text: t('error.user_not_confirmed.check_again'),
        }}
      />
    </div>
  );
};

export default UserNotConfirmed;
