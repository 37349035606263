import {useEffect, useState} from "react";
import {ICertificateForm} from "../../common/models/record";
import api from "../../common/api";
import {Card, CardContent, CardHeader, CircularProgress, createStyles, makeStyles} from "@material-ui/core";
import {ITheme} from "../../common/theme";
import ErrorInfoBox from "../../components/ErrorInfoBox";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import {observer} from "mobx-react-lite";
import {useStore} from "../../common/stores/store";
import {certWalletsPath, getNewFormPath} from "../../common/constants/routes";
import LoginRequired from "../../components/LoginRequired";


const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: {...theme.mixins.verticalAlign, height: '100%'},
    table: {
      minWidth: 480,
    },
    wrapper: {
      ...theme.mixins.wrapper,
      minHeight: '80%',
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
    errorWrapper: theme.mixins.fullstopWrapper,
    center: {
      textAlign: 'center',
    },
    listWrapper: {
      display: "flex",
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    listItem: {
      margin: theme.spacing(2),
    },
    topSection: {
      ...theme.mixins.verticalAlign,
      width: '100%',
      marginBottom: theme.spacing(4),
    },
    searchBarRoot: {
      flexGrow: 1,
      '& .MuiOutlinedInput-root': {
        '&:hover fieldSet': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        border: '2px solid',
        borderRadius: '6px',
      },
    },
    addRecordButton: {
      ...theme.mixins.defaultButton,
      fontWeight: theme.typography.fontWeightMedium,
      marginRight: theme.spacing(2),
    },
    nameCell: {
      ...theme.mixins.overflowEllipsis,
      maxWidth: '180px',
      [theme.breakpoints.down('xs')]: {
        width: '100px',
        maxWidth: '100px',
      },
    },
    walletButtonLabel: {
      ...theme.mixins.overflowEllipsis,
      display: 'block',
    },
    itemContent: {
      cursor: 'pointer'
    }
  })
);

const CertificateFormsF = () => {
  const {
    navigationStore,
    userStore,
  } = useStore();
  const {t} = useTranslation(['records', 'common']);
  const [forms, setForms] = useState([] as ICertificateForm[]);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    api.Records.myForms().then((r) => {
      setForms(r as any as ICertificateForm[]);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userStore.loggedIn) {
    return <LoginRequired/>;
  }

  if (!loading && !forms.length) {
    return <div className={classes.errorWrapper}>
      <ErrorInfoBox
        title={t('certificate.error.no_issuer_wallet.title')}
        subtitle={t('certificate.error.no_issuer_wallet.subtitle')}
        buttonText={t('common:button.add')}
        onClick={() => {
          navigationStore.push({
            pathname: certWalletsPath,
            state: {
              comingFromNewRecordForm: true,
            },
          })
        }}
      />
    </div>
  }

  return <div className={classes.wrapper}>
    {loading ?
      (<div className={classes.verticalAlign}>
        <CircularProgress color={'primary'}/>
      </div>)
      :
      (forms.length ?
          <div className={classes.listWrapper}>{forms.map((form) =>
            <Card key={form.formId} className={classes.listItem}>
              <CardHeader className={classes.center} title={form.formId}></CardHeader>
              <CardContent onClick={() => {
                navigationStore.push(getNewFormPath(form.formId));
              }} className={classes.itemContent}>
                <ReactSVG
                  src={form.certUrl}
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', `height:max(12vh,12vw,200px);width:max(12vh,12vw,200px);display:block;`)
                  }}
                />
              </CardContent>
            </Card>)}
          </div>
      :
        null
      )}
  </div>
}


export const CertificateForms = observer(CertificateFormsF);
