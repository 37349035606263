import { useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Suspense, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import { ITheme } from './theme';
import { useStore } from './stores/store';
import LoadingPage from '../components/LoadingPage/LoadingPage';
import Router from './Router';
import Dialog from './Dialog';
import ErrorInfoBox from '../components/ErrorInfoBox';
import {Route, Switch, useLocation} from "react-router-dom";
import {Certificates} from "../features/Certificates";
import {certificatePath} from "./constants/routes";
import {matchPath} from "react-router";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    topbar: {
      ...theme.mixins.toolbar, // necessary for content to be below top bar
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
    },
  })
);

const Container = () => {
  const {pathname} = useLocation();
  const { commonStore } = useStore();
  const theme = useTheme();
  const classes = useStyles();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isCertificateDisplayPath = matchPath(pathname, {
    path: certificatePath,
    exact: true,
  });

  useEffect(() => {
    if (!isCertificateDisplayPath)  {
      commonStore.setAppLoading(true);
      commonStore.initialize();
    } else {
      commonStore.setAppLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonStore, isCertificateDisplayPath]);

  return commonStore.appLoading ? (
    <LoadingPage />
  ) : (
    <Switch>
      <Route path={certificatePath}>
        <Certificates />
      </Route>
    <div className={classes.root}>
      {matchesSmallScreen ? (
        <Topbar handleDrawerToggle={handleDrawerToggle} />
      ) : null}

      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <main className={classes.mainContent}>
        <div className={classes.topbar} />
        {commonStore.appError ? (
          <ErrorInfoBox
            title={commonStore.appError.title}
            subtitle={commonStore.appError.message}
          />
        ) : (
          <Suspense fallback={<LoadingPage />}>
            <Router />
          </Suspense>
        )}
      </main>
      <Dialog />

      <ToastContainer
        position="bottom-right"
        transition={Slide}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    </Switch>
  );
};

export default observer(Container);
