import i18next from 'i18next';
import { action, makeObservable, observable } from 'mobx';
import api from '../api';
import { IError } from '../models/error';
import { ICreateWallet, IWallet } from '../models/wallet';
import errorNotify from '../utils/notifications/errorNotify';

export default class WalletStore {
  wallets: IWallet[] = [];
  walletsLoading = true;
  newWalletData: ICreateWallet | null = null;
  loadingNewWalletData = false;
  error: IError | null = null;

  constructor() {
    makeObservable(this, {
      walletsLoading: observable,
      wallets: observable,
      newWalletData: observable,
      loadingNewWalletData: observable,
      error: observable,
      setError: action,
      loadWallets: action,
      setWallets: action,
      setWalletsLoading: action,
      setLoadingNewWalletData: action,
      setNewWalletData: action,
      initNewWallet: action,
      deleteWallet: action,
    });
  }

  setError = (error: IError | null) => {
    this.error = error;
  };

  setWallets = (wallets: IWallet[]) => {
    this.wallets = wallets;
  };

  setWalletsLoading = (walletsLoading: boolean) => {
    this.walletsLoading = walletsLoading;
  };

  setNewWalletData = (newWalletData: ICreateWallet | null) => {
    this.newWalletData = newWalletData;
  };

  setLoadingNewWalletData = (loadingNewWalletData: boolean) => {
    this.loadingNewWalletData = loadingNewWalletData;
  };

  loadWallets = async () => {
    try {
      const { value } = await api.Wallets.list();
      this.setWallets(value);
    } catch (error) {
      errorNotify(i18next.t('error.wallet.background_load'));
    }
  };

  loadWalletsSorted = async () => {
    try {
      this.setError(null);
      this.setWalletsLoading(true);
      const { value } = await api.Wallets.list();
      this.setWallets(value.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      if (!this.newWalletData) {
        this.setError({
          title: i18next.t('title.error'),
          message: i18next.t('error.wallet.load_failed'),
        });
      } else {
        errorNotify(i18next.t('error.wallet.background_check'));
      }
    } finally {
      this.setWalletsLoading(false);
    }
  };

  initNewWallet = async () => {
    try {
      this.setLoadingNewWalletData(true);
      this.setNewWalletData(null);
      const { value } = await api.Wallets.create();
      this.setNewWalletData(value);
    } catch (error) {
      errorNotify(i18next.t('error.wallet.initalization_failed'));
    } finally {
      this.setLoadingNewWalletData(false);
    }
  };

  deleteWallet = async (id: string) => {
    try {
      this.setWalletsLoading(true);
      await api.Wallets.delete(id);
      this.setWallets(this.wallets.filter((wallet) => wallet.id !== id));
    } catch (error) {
      errorNotify(i18next.t('error.wallet.delete_failed'));
    } finally {
      this.setWalletsLoading(false);
    }
  };
}
