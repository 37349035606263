import {memo, useMemo, useState} from 'react';
import {
  createStyles,
  makeStyles,
  TextField,
  Button, Typography,
} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../common/stores/store';
import {ITheme} from '../../common/theme';
import {ICertificateForm, ICreateCertificate} from '../../common/models/record';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from "react-svg";
import {getCertById} from "../Certificates";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    wrapper: {
      minWidth: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap'
      }
    },
    formWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
    },
    error: {
      paddingBottom: '0 !important',
    },
    formControl: {
      maxWidth: '540px',
      width: '100%',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '& .MuiInput-underline:before': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
    buttons: {
      marginTop: theme.spacing(3),
      flexDirection: 'row',
    },
    formGroupWrapper: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    formLabel: {
      padding: theme.spacing(2),
    },
    mainField: {
      color: theme.palette.primary.main,
    },
    textArea: {
      background: theme.palette.background.default,
    },
    title: {
      paddingTop: theme.spacing(2),
      textTransform: 'uppercase',
      fontSize: '1.5rem',
    },
    constants: {
      paddingTop: theme.spacing(2),
    },
    constant: {
      fontSize: '1rem',
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    activateButton: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      alignSelf: 'flex-start',
      ...theme.mixins.defaultButton,
      ...theme.mixins.wideButton,
    },
    preview: {
      minWidth: '35%',
      flex: 1,
      maxHeight: '100%',
      [theme.breakpoints.down('md')]: {
        minWidth: 'unset',
        flex: 'unset',
      }
    }
  })
);

interface IProps {
  setRedirectId: React.Dispatch<React.SetStateAction<string>>;
  form?: ICertificateForm | null;
}

const CertSvg = memo(({id, certUrl}: { id: string; certUrl?: string }) => {
  const certById = getCertById(id) || certUrl;
  if (!certById) return null;

  return <ReactSVG
    src={certById}
    beforeInjection={(svg) => {
      svg.setAttribute('style', `height:100%;width:100%;display:block;`)
    }}
  />
});
const NewCertificateRecordForm = ({setRedirectId, form}: IProps) => {
  const [fields, setFields] = useState({} as Record<string, string | undefined>)
  const {t} = useTranslation(['records', 'authorizations', 'common']);
  const classes = useStyles();
  const {
    walletStore,
    recordStore,
    certificateStore,
  } = useStore();
  const [errors, setErrors] = useState({} as Record<string, string | undefined>);

  const mainField = useMemo(() => form?.mandatory.find((f) => f.main) || null, [form]);

  const validate = () => {
    const errors = form?.mandatory.reduce((accum, curr) => {
      const err = validateField(fields[curr.id], {required: true, max: curr.max, min: curr.min});
      if (err) {
        accum[curr.id] = err;
      }
      return accum;
    }, {} as Record<string, string>) || {};
    const optionalErrors = form?.optional?.reduce((accum, curr) => {
      const err = validateField(fields[curr.id], {required: false, max: curr.max, min: curr.min});
      if (err) {
        accum[curr.id] = err;
      }
      return accum;
    }, {} as Record<string, string>) || {};
    setErrors({...errors, ...optionalErrors});
    return Object.keys(errors).length === 0;
  }

  const validateField = (val = '', opts: { required?: boolean; min: string; max: string }) => {
    if (!val && opts.required) {
      return t('common:error.required');
    }
    if (val && ((val?.length || 0) > +opts.max || +opts.min > (val?.length || 0))) {
      return t('common:error.length_error', {min: opts.min, max: opts.max});
    }
    return null;
  }

  const activateRecord = async () => {
    if (!mainField && !recordName) {
      setRecordNameError(t('common:error.required'));
    }
    if (!validate() || !(mainField ? fields[mainField?.id] : recordName)) return;
    const hasIssuer = walletStore.wallets.some(
      (wallet) => wallet.ethereumAddress.toLowerCase() === form?.issuer.toLowerCase()
    );
    const payload = {
      author: hasIssuer && form?.issuer ? form?.issuer : walletStore.wallets[0].ethereumAddress || form?.issuer,
      name: (mainField?.id ? fields[mainField?.id] : recordName) || `Certificate #${new Date().toISOString().replace(/[^0-9]+/g, '').slice(0, 14)}`,
      data: JSON.stringify(fields),
      formId: form?.formId || '',
      mainProperty: (mainField?.id ? fields[mainField?.id] : recordName),
    } as ICreateCertificate;

    const result = await certificateStore.createCertificate(payload);
    if (result && result.id) {
      await recordStore.getPendingRecord();
      setRedirectId(result.id);
    }
  };

  const constants = useMemo(() =>
    form?.constants?.reduce(
      (accum, curr) =>
        [
          ...accum,
          ...(Object.entries(curr).map(([key, value]) => ({key, value})))
        ], [] as Array<{ key: string; value: string; }>) || [], [form?.constants]);

  const [recordNameError, setRecordNameError] = useState('');
  const [recordName, setRecordName] = useState('');

  const mandatoryFields = useMemo(() => (mainField ?
      [mainField, ...(form?.mandatory?.filter(f => !f.main) || [])]
      : form?.mandatory) || [],
    [form?.mandatory, mainField]);

  if (!form) return null;

  return (
    <div className={classes.wrapper}>
      <form className={classes.formWrapper} autoComplete="off">
        {form.mandatory?.length ? <Typography className={classes.title} color="primary" variant="h4">
          {t('certificate.new.mandatory')}&nbsp;<Typography display="inline" color="textPrimary"
                                                            className={classes.title}>{t('certificate.new.fields')}</Typography>
        </Typography> : null}
        {!mainField ?
          <TextField
            color="primary"
            size="small"
            error={!!recordNameError}
            helperText={recordNameError || undefined}
            required
            onChange={(e) => {
              setRecordName(e.target.value || '');
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                setRecordNameError(t('common:error.required'));
              }
            }}
            label={t('certificate.new.recordName')}
            variant="standard"
            value={recordName || ''}
            className={`${recordNameError ? classes.error : ''} ${classes.formControl}`}
            InputLabelProps={{
              className: classes.formLabel,
            }}
          />
          : null}
        {mandatoryFields.map((field) => (
          <TextField
            key={field.id}
            color="primary"
            size={'small'}
            error={!!errors[field.id]}
            helperText={errors[field.id]}
            required
            onChange={(e) => {
              setFields({...fields, [field.id]: e.target.value || ''});
            }}
            onBlur={(e) => {
              const err = validateField(e.target.value, {required: true, max: field.max, min: field.min})
              setErrors({...errors, [field.id]: err || undefined});
            }}
            label={`${field.label} <${field.id}>`}
            variant="standard"
            value={fields[field.id] || ''}
            className={`${errors[field.id] ? classes.error : ''} ${field.type === 'text' ? classes.textArea : ''} ${classes.formControl}`}
            multiline={field.type === 'text'}
            minRows={field.type === 'text' ? 1 : undefined}
            InputLabelProps={{
              className: `${classes.formLabel} ${mainField?.id === field.id ? classes.mainField : ''}`,
            }}
          />
        ))}
        {form.optional?.length ? <Typography color="primary" className={classes.title} variant="h4">
          {t('certificate.new.optional')}&nbsp;<Typography display="inline" color="textPrimary"
                                                           className={classes.title}>{t('certificate.new.fields')}</Typography>
        </Typography> : null}
        {form.optional?.map((field) => (
          <TextField
            key={field.id}
            color="primary"
            size={'small'}
            label={`${field.label} <${field.id}>`}
            error={!!errors[field.id]}
            helperText={errors[field.id]}
            variant="standard"
            onChange={(e) => setFields({...fields, [field.id]: e.target.value || undefined})}
            value={fields[field.id] || ''}
            onBlur={(e) => {
              const err = validateField(e.target.value, {required: false, max: field.max, min: field.min})
              setErrors({...errors, [field.id]: err || undefined});
            }}
            className={`${errors[field.id] ? classes.error : ''} ${field.type === 'text' ? classes.textArea : ''} ${classes.formControl}`}
            multiline={field.type === 'text'}
            minRows={field.type === 'text' ? 1 : undefined}
            InputLabelProps={{
              className: classes.formLabel,
            }}
          />
        ))}
        {form.constants.length ? <Typography color="textSecondary" className={classes.title} variant="h4">
          {t('certificate.new.constants')}
        </Typography> : null}
        <div className={classes.constants}>
          {constants.map((constant) =>
            <Typography color="textSecondary" className={classes.constant}>
              {constant.key} = {constant.value}
            </Typography>
          )}
        </div>
        <div className={classes.buttons}>
        <Button
          className={classes.activateButton}
          variant="contained"
          color="primary"
          onClick={activateRecord}
        >
          {t('common:button.activate')}
        </Button>
          <Button
            className={classes.activateButton}
            variant="outlined"
            color="primary"
            onClick={() => {
              setErrors({})
              setFields({})
            }}
          >
            {t('common:button.discard')}
          </Button>
        </div>
      </form>
      <div className={classes.preview}>
        <CertSvg
          id={form?.formId}
          certUrl={form?.certUrl}
        />
      </div>
    </div>
  );
};

export default observer(NewCertificateRecordForm);
