import forge from 'node-forge';

export const encryptData = (data: string, key: string, iv: string) => {
  const cipher = forge.cipher.createCipher('AES-GCM', key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(data, 'raw'));
  cipher.finish();
  return { output: cipher.output, tag: cipher.mode.tag };
};

export const decryptData = (
  data: string,
  key: string,
  iv: forge.util.ByteStringBuffer,
  tag: forge.util.ByteStringBuffer
) => {
  const decipher = forge.cipher.createDecipher('AES-GCM', key);
  decipher.start({ iv, tag });
  decipher.update(forge.util.createBuffer(data, 'raw'));

  const result = decipher.finish();
  if (!result) {
    return null;
  }

  return { output: decipher.output };
};
