import i18next from 'i18next';
import { action, makeObservable, observable } from 'mobx';
import { matchPath } from 'react-router';
import CookieDialog from '../../components/CookieDialog';
import { SocketService } from '../api/socket';
import {
  emailConfirmationPath,
  identityServerRedirectPath,
  searchPath,
} from '../constants/routes';
import { IError } from '../models/error';
import { User } from '../models/user';
import storage, { storageKeys } from '../utils/storage';
import { store } from './store';
import { IWebConfig } from "../models/discoveryConfig";
import api from "../api";
import env from "@beam-australia/react-env";

export default class CommonStore {
  accessToken: string | undefined;
  discovery: IWebConfig | undefined;
  appLoading = true;
  appError: IError | null = null;
  cookiePolicyAccepted = false;
  socketService: SocketService = new SocketService(env('NOTRZR_WS'));
  // socketService: SocketService = new SocketService(`wss://api.notrzr.net/v0.5/ws`);

  constructor() {
    makeObservable(this, {
      discovery: observable,
      appLoading: observable,
      accessToken: observable,
      appError: observable,
      checkAuth: action,
      setAppLoading: action,
      setAccessToken: action,
      setAppError: action,
      acceptCookiePolicy: action,
    });
  }

  fetchDiscovery = async () => {
    const { value } = await api.WebConfig.getDiscoveryServiceSettings();
    this.discovery = value;
  }

  setAppLoading = (appLoading: boolean) => {
    this.appLoading = appLoading;
  };

  setAccessToken = (accessToken: string | undefined) => {
    this.accessToken = accessToken;
  };

  setAppError = (appError: IError | null) => {
    this.appError = appError;
  };

  initialize = async () => {
    try {
      await this.fetchDiscovery();
      await store.userStore.initUserManager();
      await this.checkAuth();
      if (this.accessToken) await store.userStore.checkUser().catch(() => {/* noop */})
      this.cookiePolicyCheck();
      this.setAppLoading(false);
    } catch (error) {
      this.setAppLoading(false);
      this.setAppError({
        title: i18next.t('common:error.app_initalization.title'),
        message: i18next.t('common:error.app_initalization.subtitle'),
      });
    }
  };

  checkAuth = async () => {
    let authCheckFinished = false;
    let timeoutElapsed = false;
    setTimeout(() => {
      timeoutElapsed = true;
      if (authCheckFinished) {
        this.setAppLoading(false);
      }
    }, 1500);

    const isReturningFromIS = matchPath(
      store.navigationStore.history.location.pathname,
      {
        path: identityServerRedirectPath,
      }
    );

    if (isReturningFromIS) {
      await store.userStore.loginCallback();
    }
    const user = await store.userStore.loadUser();
    if (user) {
      if (user.expired) {
        return store.userStore.logout();
      }
      this.setAccessToken(user.access_token);
      this.acceptCookiePolicy();
      const emailConfirmed = this.checkEmailConfirmation(user);
      if (!emailConfirmed) return;
      await store.walletStore.loadWallets();
      store.localWalletStore.initLocalWallet();
      await store.authorizationStore.fetchAuthorization();
    }

    if (isReturningFromIS) {
      const redirectUrl =
        storage.getItem(storageKeys.POST_IS_REDIRECT_URL) || searchPath;
      store.navigationStore.replace(redirectUrl);
    }

    authCheckFinished = true;
    if (timeoutElapsed) {
      this.setAppLoading(false);
    }
  };

  checkEmailConfirmation = (user: User) => {
    if (!user.profile.email_verified) {
      store.navigationStore.replace(emailConfirmationPath);
      this.setAppLoading(false);
      return false;
    }
    return true;
  };

  cookiePolicyCheck = () => {
    if (!storage.getItem(storageKeys.COOKIE_POLICY_ACCEPTED)) {
      store.dialogStore.setDialogConfig({
        open: true,
        onClose: store.dialogStore.closeDialog,
        onCancel: store.dialogStore.closeDialog,
        onConfirm: this.acceptCookiePolicy,
        title: i18next.t('common:cookie_notice.title'),
        component: CookieDialog,
        cancelButtonText: i18next.t('common:button.decline'),
        confirmButtonText: i18next.t('common:button.accept'),
      });
    } else {
      this.acceptCookiePolicy();
    }
  };

  acceptCookiePolicy = () => {
    storage.setItem(storageKeys.COOKIE_POLICY_ACCEPTED, 'true');
    this.cookiePolicyAccepted = true;
  };
}
