import {
  CircularProgress,
  makeStyles,
  SvgIcon,
  SvgIconProps,
  useTheme,
} from '@material-ui/core';
import { ReactComponent as WarningIcon } from '../../common/icons/warning.svg';
import { ReactComponent as SuccessIcon } from '../../common/icons/success.svg';
import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SHA3 } from 'sha3';
import forge from 'node-forge';
import { ITheme } from '../../common/theme';
import { HashMethod } from '../../common/models/file';

const useStyles = makeStyles((theme: ITheme) => ({
  wrapper: {
    ...theme.mixins.wrapper,
    border: '2px dashed lightgrey',
    padding: '0 2em',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '20vh',
  },
  fullScale: {
    width: '100%',
    height: '100%',
    padding: '1em 2em 0em 2em',
  },
}));

const UploadIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z"></path>
  </SvgIcon>
);

interface IProofCheck {
  fileInputRef: RefObject<HTMLInputElement>;
  hashMethod: string;
  hashToCheck: string;
}

const ProofCheck = ({ fileInputRef, hashMethod, hashToCheck }: IProofCheck) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(['records']);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [validHash, setValidHash] = useState(false);

  const selectfile = () => {
    if (fileUploading || fileUploaded) return;
    if (!fileInputRef || !fileInputRef.current) return;
    fileInputRef.current.value = '';
    fileInputRef.current.click();
  };

  const handleFile = async () => {
    if (!fileInputRef || !fileInputRef.current) return;
    setFileUploading(true);
    const files = Array.from(fileInputRef.current.files || []);
    const fileCount = files.length;
    if (fileCount !== 1) return;
    const file = files[0];
    const hashedFile = await hashFile(file);
    setValidHash(hashedFile === hashToCheck);
    setFileUploading(false);
    setFileUploaded(true);
  };

  const hashFile = (file: Blob) => {
    const reader = new FileReader();

    return new Promise<string>((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Failed to encrypt file'));
      };

      reader.onload = () => {
        const binary = reader.result as string;
        let hash = '';

        switch (hashMethod) {
          case HashMethod.SHA2_256: {
            const md = forge.md.sha256.create();
            md.update(binary, 'raw');
            hash = md.digest().toHex();
            break;
          }
          case HashMethod.SHA3_256: {
            const sha3Digest = new SHA3(256);
            sha3Digest.update(binary, 'binary');
            hash = sha3Digest.digest('hex');
            break;
          }
          case HashMethod.SHA3_512: {
            const sha3Digest = new SHA3(512);
            sha3Digest.update(binary, 'binary');
            hash = sha3Digest.digest('hex');
            break;
          }
          default:
            reject(new Error('Unknown hashing method'));
        }

        resolve(hash);
      };

      reader.readAsBinaryString(file);
    });
  };

  const showValidState = () => {
    if (validHash) {
      return (
        <div className={classes.content}>
          <SuccessIcon
            className={classes.fullScale}
            style={{ color: theme.palette.success.main }}
          />
          <p style={{ textAlign: 'center' }}>
            {t('proof.verify_success')}
            <br />
            {t('proof.verify_success_description')}
          </p>
        </div>
      );
    } else {
      return (
        <div className={classes.content}>
          <WarningIcon
            className={classes.fullScale}
            style={{ color: theme.palette.error.main }}
          />
          <p style={{ textAlign: 'center' }}>
            {t('proof.verify_failed')}
            <br />
            {t('proof.verify_failed_description')}
          </p>
        </div>
      );
    }
  };

  return (
    <div
      className={classes.wrapper}
      style={{
        cursor: fileUploading || fileUploaded ? 'default' : 'pointer',
      }}
      onClick={selectfile}
    >
      {fileUploading ? (
        <div className={classes.content}>
          <CircularProgress
            color={'primary'}
            style={{ padding: '2em', width: 'auto', height: 'auto' }}
          />
          <p>{t('proof.upload_description')}</p>
        </div>
      ) : fileUploaded ? (
        showValidState()
      ) : (
        <div className={classes.content}>
          <UploadIcon
            className={classes.fullScale}
            style={{ color: 'lightgrey' }}
          />
          <p>{t('proof.upload_description')}</p>
        </div>
      )}
      <input
        ref={fileInputRef}
        type="file"
        id="file-input"
        style={{ display: 'none' }}
        onChange={handleFile}
      />
    </div>
  );
};

export default ProofCheck;
