import { observer } from 'mobx-react-lite';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useStore } from './stores/store';

const Dialog = () => {
  const {
    dialogStore: {
      dialogConfig: {
        open,
        onClose,
        onCancel,
        onConfirm,
        component,
        title,
        text,
        cancelButtonText,
        confirmButtonText,
        singleButtonAction,
        singleButtonText,
      },
      closeDialog,
    },
  } = useStore();

  const confirm = () => {
    closeDialog();
    onConfirm();
  };

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        {component ? component() : null}
        <DialogContentText align="center" id="dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {singleButtonAction ? (
          <Button style={{ margin: 'auto' }} onClick={onCancel} color="primary">
            {singleButtonText}
          </Button>
        ) : (
          <>
            <Button onClick={onCancel} color="primary">
              {cancelButtonText}
            </Button>
            <Button onClick={confirm} color="primary" autoFocus>
              {confirmButtonText}
            </Button>
          </>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

export default observer(Dialog);
