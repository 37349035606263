import {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, CircularProgress, createStyles, makeStyles, TextField, Typography, useTheme,} from '@material-ui/core';
import {ITheme} from '../../common/theme';
import {useStore} from '../../common/stores/store';
import errorNotify from '../../common/utils/notifications/errorNotify';
import {useTranslation} from 'react-i18next';
import LoginRequired from "../../components/LoginRequired";
import {IRecord, IRecordLink, RecordLinkType} from "../../common/models/record";
import QRCode from 'qrcode.react';
import api from "../../common/api";

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: { ...theme.mixins.verticalAlign, height: '100%' },
    table: {
      minWidth: 480,
    },
    wrapper: {
      ...theme.mixins.wrapper,
      minHeight: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
    topSection: {
      ...theme.mixins.verticalAlign,
      width: '100%',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    bottomSection: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    searchBarRoot: {
      flexGrow: 1,
      maxWidth: '870px',
      borderWidth: '2px',
      '& .MuiOutlinedInput-root': {
        '&:hover fieldSet': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        border: '2px solid',
        borderRadius: '6px',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    searchButton: {
      alignSelf: 'stretch',
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: theme.spacing(8),
      },
    },
    searchLoading: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    actionButton: {
      width: '200px',
      height: '90px',
      padding: theme.spacing(3),
      transition: 'all 0.3s ease-in-out',
      // marginRight: theme.spacing(6),

      '&:last-child': {
        marginRight: 0,
      }
    },
    actions: {
      maxWidth: '870px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(4),
    },
    challenge: {
      maxWidth: '870px',
      width: '100%',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(4),
    },
    title: {
      maxWidth: '870px',
      width: '100%',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    italic: {
      fontStyle: 'italic',
    },
    titleLimit: {
      maxWidth: '800px',
    },
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    }
  })
);
const POK = () => {
  const { t } = useTranslation(['search']);
  const classes = useStyles();
  const theme = useTheme();
  const { commonStore: { socketService }, recordStore, userStore } = useStore();
  const [searchValue, setSearchValue] = useState('');
  const [found, setFound] = useState(null as null | IRecord);
  const [loadingType, setLoadingType] = useState(null as null | string);
  const [type, setType] = useState(null as null | string);
  const [challenge, setChallenge] = useState(null as null | string);
  const [result, setResult] = useState(null as null | 'success' | 'fail');
  const challengeId = useRef(null);
  const links = useRef([] as IRecordLink[]);

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const openRecordDetail = async () => {
    if (!searchValue) {
      errorNotify(t('search:error.unique_id'));
      return;
    }
    socketService.unsubscribeById('POK_SUCCESS');
    setFound(null);
    setChallenge(null);
    setLoadingType(null);
    setType(null);
    setResult(null);

    const result = await recordStore.details(searchValue);
    if (result) {
      setFound(result);
    } else {
      errorNotify(
        t('search:error.no_record_found', {
          searchValue: searchValue,
        })
      );
    }
  };

  useEffect(() => {
    if (!found) {
      links.current = [];
    }
  }, [found])

  const startProcess = async (type: string) => {
    setLoadingType(type);
    setType(type);
    try {
      const res: any = await api.Records.generatePok();
      challengeId.current = res.id || res.I;
      setChallenge(JSON.stringify({...res, id: undefined, I: res.id || res.I }));
      api.Records.getAssetLinks(found!.id).then((res) => {
        links.current = res.value;
      });
      socketService.unsubscribeById('POK_SUCCESS');
      socketService.subscribeWithId(async (event) => {
        if (event.type === 'POK_SUCCESS' || event.type === 'POK_FAILED') {
          const message = JSON.parse(event.message);
          if (!message?.challengeId || message?.challengeId !== challengeId.current) return;
          if (!message.result) setResult('fail');
          try {
            if (type === 'link') {
              if (links.current.some((link) =>
                link.id?.toLowerCase() === message.address?.toLowerCase()
                && (link.type === RecordLinkType.TO ||
                  link.type === RecordLinkType.INVITATION ||
                  link.type === RecordLinkType.SIGNATURE))) {
                setResult( 'success');
                socketService.unsubscribeById('POK_SUCCESS');
              }
              const possibleLinks = links.current.filter((link) => (link.type === RecordLinkType.TO ||
                link.type === RecordLinkType.INVITATION ||
                link.type === RecordLinkType.SIGNATURE));
              const foundLink = await possibleLinks.reduce(async (accum, link) => {
                const r = await accum;
                if (r) return r;
                try {
                  const {value} = await api.Records.details(link.id);
                  return value.author?.toLowerCase() === message.address?.toLowerCase();
                } catch {
                  return false;
                }
              }, Promise.resolve(false));

              setResult( foundLink ? 'success' : 'fail');
              socketService.unsubscribeById('POK_SUCCESS');
              return;
            }
            if (type === 'signature') {
              if (links.current.some((link) =>
                link.id?.toLowerCase() === message.address?.toLowerCase()
                && link.type === 'SIGNATURE')) {
                setResult( 'success');
                socketService.unsubscribeById('POK_SUCCESS');
              }
              const possibleLinks = links.current.filter((link) => link.type === 'SIGNATURE');
              const foundLink = await possibleLinks.reduce(async (accum, link) => {
                const r = await accum;
                if (r) return r;
                try {
                  const {value} = await api.Records.details(link.id);
                  return value.author?.toLowerCase() === message.address?.toLowerCase();
                } catch {
                  return false;
                }
              }, Promise.resolve(false));

              setResult( foundLink ? 'success' : 'fail');
              socketService.unsubscribeById('POK_SUCCESS');
              return;
            }
            if (type === 'token') {
              socketService.unsubscribeById('POK_SUCCESS');
              api.Records.getTokens(found!.id, message.address).then((res) => {
                setResult(+res?.value?.sparks > 0 ? 'success' : 'fail');
              });
              return;
            }
          } catch (err) {
            console.log(err);
          }
          setResult('fail');
        }
      }, 'POK_SUCCESS');
      setLoadingType(null);
    } catch {
      setChallenge(null);
      setLoadingType(null);
      errorNotify(
        t('search:pok.error.genFail')
      );
    }
  }

  const onBack = () => {
    setChallenge(null);
    setType(null);
    setResult(null);
    socketService.unsubscribeById('POK_SUCCESS');
  }

  if (!userStore.loggedIn) {
    return <LoginRequired />;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.topSection}>
        <TextField
          color="primary"
          classes={{ root: classes.searchBarRoot }}
          label={t('search:search.by_id')}
          type="text"
          variant="outlined"
          size={'small'}
          value={searchValue}
          onChange={updateSearchValue}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.searchButton}
          onClick={!recordStore.recordDetailsLoading && !loadingType ? openRecordDetail : undefined}
        >
          <span style={{ color: recordStore.recordDetailsLoading ? 'transparent' : undefined }}>
            {t('search:search.button')}
          </span>
          {recordStore.recordDetailsLoading ? <div className={classes.searchLoading}><CircularProgress size={18} color={'inherit'}/></div> : null}
        </Button>
      </div>
      {found ? <div className={classes.bottomSection}>
        <div className={classes.title}>
          <Typography className={classes.titleLimit} component="h5" align="left" variant="h5">
            {found?.name}
          </Typography>
          {challenge
            ? <Button
                onClick={onBack}
                variant="text"
                color="primary">
                {t('search:pok.back')}
              </Button>
            : null}
        </div>
        {!challenge
          ? <div className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={!loadingType ? () => startProcess('link') : undefined}
            >
              <span style={{ color: loadingType === 'link' ? 'transparent' : undefined }}>
                {t('search:pok.link.button')}
              </span>
              {loadingType === 'link' ? <div className={classes.searchLoading}><CircularProgress size={30} color={'inherit'}/></div> : null}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={!loadingType ? () => startProcess('token') : undefined}
            >
              <span style={{ color: loadingType === 'token' ? 'transparent' : undefined }}>
                {t('search:pok.token.button')}
              </span>
              {loadingType === 'token' ? <div className={classes.searchLoading}><CircularProgress size={30} color="inherit"/></div> : null}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={!loadingType ? () => startProcess('signature') : undefined}
            >
              <span style={{ color: loadingType === 'signature' ? 'transparent' : undefined }}>
                {t('search:pok.signature.button')}
              </span>
              {loadingType === 'signature' ? <div className={classes.searchLoading}><CircularProgress size={30} color="inherit"/></div> : null}
            </Button>
          </div>
        : <div className={classes.challenge}>
            <Typography component="div" align="left" variant="subtitle1" className={classes.italic}>
              {t(`search:pok.${type || 'link'}.description`)}
            </Typography>
            <QRCode
              fgColor={result ? (result === 'success' ? theme.palette.success.main : theme.palette.error.main) : 'black'}
              value={challenge}
              includeMargin={true}
              size={200}
            />
              {result ? <Typography className={result === 'success' ? classes.success : classes.error} component="h6" align="left" variant="h6">
                {t(`search:pok.${result}`)}
              </Typography> : null}
        </div>}
      </div> : null}
    </div>
  );
};

export default observer(POK);
