import {
  CircularProgress,
  makeStyles,
  createStyles,
  Typography,
} from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { observer } from 'mobx-react-lite';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../common/stores/store';
import { ITheme } from '../../common/theme';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    wrapper: { ...theme.mixins.wrapper },
    confirmButton: {
      marginTop: theme.spacing(2),
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      width: '35vh',
    },
    autoScale: {
      width: 'auto',
      height: 'auto',
    },
  })
);

interface IAuthorizationQrProps {
  address: string;
  publicKey: string;
}

const AuthorizationQr = ({ address, publicKey }: IAuthorizationQrProps) => {
  const { t } = useTranslation(['authorizations']);
  const classes = useStyles();

  const {
    authorizationStore: { /*authorizationLoading,*/ authorizationProgress },
  } = useStore();

/*  if (authorizationLoading) {
    return <CircularProgress color="primary" />;
  } else {*/
    return (
      <div className={classes.wrapper}>
        {authorizationProgress === 'PROGRESS' ? (
          <div className={classes.content}>
            <CircularProgress
              color={'primary'}
              style={{ padding: '2em', width: 'auto', height: 'auto' }}
            />
          </div>
        ) : authorizationProgress === 'SUCCESS' ? (
          <div className={classes.content}>
            <CheckCircleOutlineOutlinedIcon className={classes.autoScale} />
            <Typography color="primary" align="center">
              {t('qr_scan.success')}
            </Typography>
          </div>
        ) : authorizationProgress === 'FAILED' ? (
          <div className={classes.content}>
            <CancelOutlinedIcon className={classes.autoScale} />
            <Typography color="primary" align="center">
              {t('qr_scan.failed')}
            </Typography>
          </div>
        ) : (
          <div className={classes.content}>
            <QRCode
              value={`{"T":"auth","P":"${publicKey}","A":"${address}"}`}
              includeMargin
              size={256}
            />
            <Typography color="primary" align="center">
              {t('qr_scan.description')}
            </Typography>
          </div>
        )}
      </div>
    );
  // }
};

export default observer(AuthorizationQr);
