import {useEffect, useMemo, useRef, useState} from 'react';
import {
  createStyles,
  makeStyles,
  CircularProgress,
  Typography, useTheme,
} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../common/stores/store';
import {ITheme} from '../../common/theme';
import LoginRequired from '../../components/LoginRequired';
import InfoBox from '../../components/InfoBox';
import {certificatePath, certWalletsPath} from '../../common/constants/routes';
import NewRecordForm from './NewCertificateRecordForm';
import ErrorInfoBox from '../../components/ErrorInfoBox';
import {useParams} from "react-router-dom";
import api from "../../common/api";
import {ICertificateForm} from "../../common/models/record";
import errorNotify from "../../common/utils/notifications/errorNotify";
import QRCode from "qrcode.react";

let clearTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: {
      ...theme.mixins.verticalAlign,
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    wrapper: {
      ...theme.mixins.wrapper,
      minHeight: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
    pendingRecordInfo: {
      marginTop: theme.spacing(2),
    },
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    }
  })
);

const NewCertificateRecord = () => {
  const {id} = useParams<{ id: string }>();
  const {t} = useTranslation(['records', 'search', 'common', 'authorizations']);
  const classes = useStyles();
  const {
    commonStore: {socketService},
    userStore,
    dialogStore,
    walletStore,
    navigationStore,
    recordStore,
    certificateStore,
  } = useStore();
  const [redirectId, setRedirectId] = useState('');
  const [pendingRecordsLoaded, isPendingRecordsLoaded] = useState(false);
  const theme = useTheme();
  const [, setCheckingAuth] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const challengeId = useRef<null | string>(null);
  const [result, setResult] = useState<null | 'success' | 'fail'>(null);
  const [challenge, setChallenge] = useState<null | string>(null);

  const [form, setForm] = useState<ICertificateForm | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) return;
    api.Records.formById(id).then((r) => {
      const nextForm = r as any as ICertificateForm;
      setForm(nextForm);
      setCheckingAuth(true);
      startProcess(nextForm?.issuer);
      setLoading(true);
    }).catch(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (userStore.loggedIn/* && recordStore.pendingRecord*/) {
      recordStore.getPendingRecord();
    }
  }, [recordStore, userStore.loggedIn]);

  useEffect(() => {
    if (recordStore.pendingRecord) {
      clearTimer = setTimeout(recordStore.getPendingRecord, 2000);
      return () => clearTimeout(clearTimer);
    }
  }, [recordStore, recordStore.pendingRecord]);

  useEffect(() => {
    if (!recordStore.pendingRecordLoading) {
      isPendingRecordsLoaded(true);
    }
  }, [recordStore.pendingRecordLoading]);

  useEffect(() => {
    if (!recordStore.pendingRecordLoading && !recordStore.pendingRecord) {
      if (redirectId) {
        // navigationStore.push(`${certificatePath}?id=${redirectId}&form=${form?.formId}`);
        dialogStore.setDialogConfig({
          open: true,
          onClose: dialogStore.closeDialog,
          onCancel: dialogStore.closeDialog,
          onConfirm: () => {
            window.open(`${window.location.origin}${certificatePath}?id=${redirectId}&form=${form?.formId}`, '_blank');
          },
          title: t('certificate.new.readyTitle'),
          text: t('certificate.new.readyDescription'),
          cancelButtonText: t('common:button.no'),
          confirmButtonText: t('common:button.yes'),
        });
      } else {
        walletStore.loadWalletsSorted();
      }
    }
  }, [walletStore, recordStore, recordStore.pendingRecordLoading, redirectId, navigationStore, form?.formId, dialogStore, t]);

  const hasIssuer = useMemo(() => walletStore.wallets.some(
    (wallet) => wallet.ethereumAddress.toLowerCase() === form?.issuer.toLowerCase()
  ), [form?.issuer, walletStore.wallets]);

  const openWalletsView = () => {
    navigationStore.push({
      pathname: certWalletsPath,
      state: {
        comingFromNewRecordForm: true,
      },
    });
  };

  useEffect(() => {
    if (result === 'success') {
      setTimeout(() => setAuthorized(true), 1000)
    }
  }, [result]);

  if (!userStore.loggedIn) {
    return <LoginRequired/>;
  }

  if (recordStore.error) {
    const {error} = recordStore;
    return <ErrorInfoBox title={error.title} subtitle={error.message}/>;
  }

  if (form && !hasIssuer) {
    return <ErrorInfoBox
      title={t('certificate.error.no_issuer_wallet.title')}
      subtitle={t('certificate.error.no_issuer_wallet.subtitle')}
    />;
  }

  const {recordsLoading, pendingRecord, pendingRecordLoading} = recordStore;

  const startProcess = async (address?: string) => {
    if (!address) {
      setCheckingAuth(false);
      return;
    }
    setCheckingAuth(true);
    try {
      try {
        const checkRes: any = await api.Records.getPokByAddress(address);
        if (checkRes?.result) {
          socketService.unsubscribeById('POK_SUCCESS');
          setLoading(false);
          setAuthorized(true);
          return;
        }
      } catch {
        //noop
      }
      const res: any = await api.Records.generatePok(address);
      challengeId.current = res.id || res.I;
      setChallenge(JSON.stringify({...res, id: undefined, I: res.id || res.I }));
      socketService.unsubscribeById('POK_SUCCESS');
      socketService.subscribeWithId(async (event) => {
        if (event.type === 'POK_SUCCESS' || event.type === 'POK_FAILED') {
          const message = JSON.parse(event.message);
          if (!message?.challengeId || message?.challengeId !== challengeId.current) return;
          if (message?.result === true && message?.address?.toLowerCase() === address?.toLowerCase()) return setResult('success');
          setResult('fail');
        }
      }, 'POK_SUCCESS');
      setLoading(false);
    } catch {
      setChallenge(null);
      setCheckingAuth(false);
      setLoading(false);
      errorNotify(
        t('search:pok.error.genFail')
      );
    }
  }

  if (!authorized && challenge) {
    return <div className={classes.wrapper}>
      <div className={classes.verticalAlign}>
        <QRCode
          fgColor={result ? (result === 'success' ? theme.palette.success.main : theme.palette.error.main) : 'black'}
          value={challenge}
          includeMargin={true}
          size={200}
        />
        {result
          ? <Typography className={result === 'success' ? classes.success : classes.error} component="h6" align="left"
                        variant="h6">
            {t(`search:pok.${result}`)}
          </Typography>
          : <Typography color="primary" align="center">
            {t('authorizations:qr_scan.description')}
          </Typography>
        }
    </div>
  </div>
  }

  return (
    <div className={classes.wrapper}>
      {walletStore.walletsLoading ||
      recordsLoading ||
      loading ||
      pendingRecord ||
      certificateStore.certificatesLoading ||
      pendingRecordLoading ||
      !pendingRecordsLoaded ? (
        <div className={classes.verticalAlign} style={{maxWidth: '500px'}}>
          <CircularProgress color={'primary'}/>
          {pendingRecord ? (
            <>
              <Typography color="primary" className={classes.pendingRecordInfo}>
                {t('new_record.processing.info_paragraph_1', {
                  recordId: pendingRecord.id,
                })}
              </Typography>
              <Typography color="primary" className={classes.pendingRecordInfo}>
                {t('new_record.processing.state_text')}{' '}
                {t(`new_record.state.${pendingRecord?.state?.toLowerCase()}`)}
              </Typography>

              <Typography
                variant={'h6'}
                color="primary"
                className={classes.pendingRecordInfo}
              >
                {t('new_record.processing.info_paragraph_2')}
              </Typography>
              <Typography
                align="center"
                color="primary"
                className={classes.pendingRecordInfo}
              >
                {t('new_record.processing.info_paragraph_3')}
              </Typography>
            </>
          ) : null}
        </div>
      ) : walletStore.wallets.length ? (
        <NewRecordForm form={form} setRedirectId={setRedirectId}/>
      ) : (
        <div className={classes.verticalAlign}>
          <InfoBox
            title={t('new_record.no_wallet.title')}
            subtitle={t('new_record.no_wallet.subtitle')}
            leftButton={{
              variant: 'contained',
              onClick: openWalletsView,
              text: t('common:button.add'),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default observer(NewCertificateRecord);
