import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  makeStyles,
  createStyles,
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { ITheme } from '../../common/theme';
import { useStore } from '../../common/stores/store';
import { recordsPath } from '../../common/constants/routes';
import errorNotify from '../../common/utils/notifications/errorNotify';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: { ...theme.mixins.verticalAlign, height: '100%' },
    table: {
      minWidth: 480,
    },
    wrapper: {
      ...theme.mixins.wrapper,
      minHeight: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
    topSection: {
      ...theme.mixins.verticalAlign,
      width: '100%',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    searchBarRoot: {
      flexGrow: 1,
      maxWidth: '870px',
      borderWidth: '2px',
      '& .MuiOutlinedInput-root': {
        '&:hover fieldSet': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        border: '2px solid',
        borderRadius: '6px',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    searchButton: {
      alignSelf: 'stretch',
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: theme.spacing(8),
      },
    },
  })
);
const Search = () => {
  const { t } = useTranslation(['search']);
  const classes = useStyles();
  const { navigationStore, recordStore } = useStore();
  const [searchValue, setSearchValue] = useState('');

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const openRecordDetail = async () => {
    if (!searchValue) {
      errorNotify(t('search:error.unique_id'));
      return;
    }

    const result = await recordStore.details(searchValue);
    if (result) {
      navigationStore.push({
        pathname: `${recordsPath}/${searchValue}`,
        state: {
          comingFromSearch: true,
        },
      });
    } else {
      errorNotify(
        t('search:error.no_record_found', {
          searchValue: searchValue,
        })
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      {recordStore.recordDetailsLoading ? (
        <div className={classes.verticalAlign}>
          <CircularProgress color={'primary'} />
        </div>
      ) : (
        <div className={classes.topSection}>
          <TextField
            color="primary"
            classes={{ root: classes.searchBarRoot }}
            label={t('search:search.by_id')}
            type="text"
            variant="outlined"
            size={'small'}
            value={searchValue}
            onChange={updateSearchValue}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.searchButton}
            onClick={openRecordDetail}
          >
            {t('search:search.button')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(Search);
