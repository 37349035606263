import { useState, useEffect } from 'react';

export const useBbox = () => {
  const [bbox, setBbox] = useState({} as ReturnType<HTMLElement["getBoundingClientRect"]> | Record<string, number>);
  const [trigger, setTrigger] = useState('');

  const set = () => setBbox(trigger ? document.querySelector(trigger)?.getBoundingClientRect() || {} : {});
  const updateRef = (query: string) => setTrigger(query);

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
    // eslint-disable-next-line
  }, [trigger]);

  return [bbox, updateRef] as const;
};
